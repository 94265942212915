import { SvgIcon } from '@mui/material';
import styled from 'styled-components';

export const Amount = styled.div`
  text-align: center;
  font-size: 1.5rem;
  padding: 0 1rem;
`;

export const Icon = styled(SvgIcon)`
  color: #848484;
  width: 30px !important;
  height: 30px !important;
`;
