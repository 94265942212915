import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Text, ContactButton } from '../index';
import { CommentBox, CommentContent, CommentLabel, CommentTitle, CommentFooter } from './styles';
import { Pagination } from '@mui/material';
import { contactTypes } from '../../enums';
import moment from 'moment';

const Breadcrumb = ({
  contacts,
  entity,
  entityType,
  service,
  loadingDataFunc,
  lastContact,
  setLastContact,
}) => {
  const [page, setPage] = useState(1);
  const count = contacts.length;

  const handlePage = (event, value) => {
    setLastContact(contacts[value - 1]);
    setPage(value);
  };

  useEffect(async () => {
    setPage(1);
    setLastContact(contacts[page - 1]);
  }, [contacts]);

  return (
    <>
      <h3>
        {'Contato realizado em ' +
          moment(lastContact.contactDate).startOf('day').format('DD/MM/YYYY')}
      </h3>

      <Text text={'Por: ' + lastContact.user.name} />

      <CommentBox>
        <CommentLabel>Comentário</CommentLabel>
        <CommentTitle className={'row'}>
          <Col xs={6}>{lastContact.focalPoint}</Col>
          <Col xs={6} className={'d-flex justify-content-end'}>
            {`${contactTypes[lastContact.contactType].label}${
              lastContact.contactDesc ? ` - ${lastContact.contactDesc}` : ''
            }`}
          </Col>
        </CommentTitle>
        <CommentContent>{lastContact.comment ?? ''}</CommentContent>
        <CommentFooter>
          <Col xs={12} className={'d-flex justify-content-center align-items-center mt-2'}>
            <Pagination
              variant={'outlined'}
              color={'success'}
              count={count}
              page={page}
              onChange={handlePage}
            />
          </Col>
          <Col xs={12} className={'d-flex justify-content-end align-items-end mt-2'}>
            <ContactButton
              text={'Novo contato'}
              variant={'outlined'}
              entity={entity}
              entityType={entityType}
              service={service}
              loadingDataFunc={loadingDataFunc}
              fillColumn
            />
          </Col>
        </CommentFooter>
      </CommentBox>
    </>
  );
};

Breadcrumb.propTypes = {
  contacts: PropTypes.array,
  redirectButton: PropTypes.func,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  service: PropTypes.object,
  loadingDataFunc: PropTypes.func,
  lastContact: PropTypes.object,
  setLastContact: PropTypes.func,
};

export default Breadcrumb;
