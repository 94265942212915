import { Select, InputLabel as MUIInputLabel, FormControl } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const StyleSelect = styled(Select)`
  width: 100%;
  text-align: left;
`;

export const Form = styled(FormControl)(({ label, error }) => ({
  '& label.Mui-focused': {
    color: `${!error ? colors.primaryDark.element : ''}`,
  },
  '& div svg.MuiSvgIcon-root': {
    transition: `transform 0.2s`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `${!error ? colors.primaryDark.element : ''}`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${!error ? colors.primaryDark.element : ''}`,
      padding: `0 ${!label ? '50%' : 'unset'};`,
    },
    '&:hover fieldset': {
      borderColor: `${!error ? colors.primaryDark.element : ''}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${!error ? colors.primaryDark.element : ''}`,
    },
  },
  width: '100%',
}));

export const InputLabel = styled(MUIInputLabel)``;
