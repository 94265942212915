import styled from 'styled-components';
import { TextField } from '@mui/material';

export const Input = styled(TextField)`
  background-color: aliceblue;
  width: 100%;
  margin: 5px 0;
`;

export const Text = styled.text`
  margin: 20px;
  color: #1fdf6f;
  font-weight: ;
  font-size: x-large;
  font-weight: 500;
`;

export const AreaReactInformation = styled.div`
  margin: 20px;
  border: 5px solid;
  border-radius: 5px;
  border-color: #78e39f;
`;
