import PropTypes from 'prop-types';
import React from 'react';
import {
  Accordion as StyledAccordion,
  HistoryAccordion,
  HistoryHeader,
  Header,
  Body,
  Item,
} from './styles';

const Accordion = ({ options, changesHistory }) => {
  if (changesHistory) {
    return (
      <HistoryAccordion>
        {options.map(({ header, body }, i) => (
          <Item eventKey={i} key={i}>
            <HistoryHeader src={header.icon}>{header.label}</HistoryHeader>
            <Body>{body}</Body>
          </Item>
        ))}
      </HistoryAccordion>
    );
  }

  return (
    <StyledAccordion>
      {options.map(({ header, body }, i) => (
        <Item eventKey={i} key={i}>
          <Header src={header.icon}>{header.label}</Header>
          <Body>{body}</Body>
        </Item>
      ))}
    </StyledAccordion>
  );
};

Accordion.propTypes = {
  options: PropTypes.array,
  changesHistory: PropTypes.bool,
};

export default Accordion;
