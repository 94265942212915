import React, { useContext, useEffect, useState } from 'react';
import { Button, Badge, DataGrid, Input } from '../../components';
import { Row, Col } from 'react-bootstrap';
import { opportunityStatus } from '../../enums';
import opportunityService from '../../services/opportunity';
import PropTypes from 'prop-types';
import { DefaultContext } from '../../context';
import { sortComparatorByDate } from '../../utils/helpers';
import { Line } from '../Lead/styles';

const Leads = ({ history }) => {
  const { handleModal, setLoading } = useContext(DefaultContext);
  const [opportunities, setOpportunities] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(async () => {
    await getOpportunities();
  }, []);

  const getOpportunities = async () => {
    setLoading(true);

    const { ok, data, message } = await opportunityService.list();
    if (ok && data) {
      data.map((opportunity) => {
        opportunity.status = opportunityStatus[opportunity.status].label;
        opportunity.includedAt = opportunity.includedAt ?? '';
        opportunity.lastContact =
          opportunity.lastContact && opportunity.lastContact.nextContactDate !== '0'
            ? opportunity.lastContact.nextContactDate
            : 'Não contactado';
        return opportunity;
      });
      setOpportunities(data);
    } else
      handleModal({
        title: 'Informação',
        message,
        show: true,
      });

    return setLoading(false);
  };

  const toOpportunity = (id) => {
    history.push(`/oportunidade/${id}`);
  };

  const action = async (key) => {
    if (key === 'Enter') await getOpportunities(search);
  };

  const columnsOpportunities = [
    {
      field: 'name',
      headerName: 'Nome',
      type: 'string',
      flex: 0.6,
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 0.6,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'observation',
      headerName: 'Observação',
      type: 'string',
      flex: 0.6,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'stage',
      headerName: 'Estagio',
      type: 'string',
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => `${value}%`,
    },
    {
      field: 'lastContact',
      headerName: 'Próximo contato',
      type: 'date',
      flex: 0.4,
      headerAlign: 'center',
      align: 'center',
      sortComparator: sortComparatorByDate,
    },
    {
      field: 'priorityValue',
      headerName: 'Prioridade',
      type: 'string',
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => {
        return <Badge priority={value} />;
      },
    },
  ];

  return (
    <>
      <Row className={'mb-3'}>
        <Col align={'left'}>
          <h1>Oportunidades</h1>
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col>
          <Line />
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col xs={12} md={6} className={'d-flex justify-content-center'}>
          <Input
            id={'search'}
            name={'search'}
            placeholder={'Pesquisar'}
            label={'Pesquisar'}
            size={'small'}
            search
            action={action}
            onChange={(e) => setSearch(e.target.value)}
            onClick={() => getOpportunities(search)}
          />
        </Col>
        <Col
          xs={12}
          md={6}
          className={'mt-md-0 mt-3 d-flex justify-content-center justify-content-md-end'}
        >
          <Button variant="outlined" text={'Nova Oportunidade'} onClick={() => toOpportunity('')} />
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col>
          <DataGrid
            rows={opportunities}
            columns={columnsOpportunities}
            onCellClick={(e) => toOpportunity(e.id)}
          />
        </Col>
      </Row>
    </>
  );
};

Leads.propTypes = {
  history: PropTypes.object,
};

export default Leads;
