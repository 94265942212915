import styled from 'styled-components';
import { Container as BSComponent } from 'react-bootstrap';
import { bootstrapMax } from '../../utils/devices';

export const Container = styled(BSComponent)`
  padding: 130px 15px 0;

  @media ${bootstrapMax.md} {
    padding: 120px 30px 0;
  }

  @media ${bootstrapMax.xs} {
    padding: 90px 15px 0;
  }
`;
