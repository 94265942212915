import { axiosDefault, handleErrors } from '../utils/defaults';

const user = {
  list: async (search) => {
    try {
      const axios = axiosDefault();
      const data = await axios.get(`/api/user/${JSON.stringify(search)}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  get: async (id) => {
    try {
      const axios = axiosDefault();
      const data = await axios.get(`/api/user/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  save: async (user) => {
    try {
      const axios = axiosDefault();
      const data = await axios.post(`/api/user/`, user);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  edit: async (user) => {
    try {
      const axios = axiosDefault();
      const data = await axios.put(`/api/user/${user.id}`, user);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  delete: async (id) => {
    try {
      const axios = axiosDefault();
      const data = await axios.delete(`/api/user/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default user;
