import PropTypes from 'prop-types';
import React from 'react';
import { Button as StyleButton, MiniDeleteButton } from './style';

export const Button = ({
  onClick,
  variant,
  className,
  text,
  danger,
  fillColumn,
  fillCell,
  miniDelete,
  secondary,
  disabled,
}) => {
  if (danger) {
    return (
      <StyleButton
        onClick={onClick}
        variant={variant}
        className={className}
        danger="true"
        fill={fillColumn ? 'true' : ''}
      >
        {text}
      </StyleButton>
    );
  }

  if (miniDelete) {
    return <MiniDeleteButton onClick={onClick} />;
  }

  if (fillCell)
    return (
      <StyleButton secondary={secondary} onClick={onClick} fillCell="true" disabled={disabled}>
        {text}
      </StyleButton>
    );

  return (
    <StyleButton
      secondary={secondary ? 'true' : ''}
      onClick={onClick}
      variant={variant}
      className={className}
      fill={fillColumn ? 'true' : ''}
      disabled={disabled}
    >
      {text}
    </StyleButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  fillColumn: PropTypes.bool,
  fillCell: PropTypes.bool,
  miniDelete: PropTypes.bool,
  secondary: PropTypes.bool,
};

export default Button;
