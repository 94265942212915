import { RadioGroup } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Radio as RadioButton, FormLabel } from './styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export const Radio = ({ label, onChange, value, list }) => {
  return (
    <FormControl>
      <FormLabel id="installment-type-value">{label}</FormLabel>
      <RadioGroup
        aria-labelledby="installment-type-value"
        name="installment-type-value"
        value={value}
        onChange={onChange}
      >
        {list.map((i) => (
          <FormControlLabel
            key={i.value}
            value={i.value}
            control={<RadioButton />}
            label={i.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

Radio.propTypes = {
  label: PropTypes.string,
  list: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Radio;
