import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material';
import { colors } from '../../utils/colors';

export const Table = styled(DataGrid)`
  background-color: ${colors.white};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;

  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-columnHeadersInner {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: ${colors.primaryLight.element};

    .MuiDataGrid-columnHeaderTitle {
      color: ${colors.white};
    }

    .MuiIconButton-root {
      color: ${colors.white};
    }

    > .MuiDataGrid-withBorder {
      border-right: 1px solid ${colors.white}33;
    }

    > .MuiDataGrid-withBorder:last-of-type {
      border-right: 1px solid ${colors.primaryLight.element};
    }
  }

  .Grid-cell-name {
    min-width: 100px;
    max-width: 1000px !important;
    width: 100% !important;
  }

  & .MuiDataGrid-row.Mui-selected {
    background-color: ${colors.secondaryLight.element}43 !important;
  }

  .MuiDataGrid-row:nth-of-type(even),
  .MuiDataGrid-columnsContainer {
    background-color: ${colors.primaryLight.element}11;
  }

  .MuiDataGrid-columnsContainer {
    background-color: ${colors.primaryLight.element};
  }

  .MuiDataGrid-columnHeaderTitle {
    color: ${colors.primaryLight.element};
    white-space: break-spaces;
    line-height: 20px;
    text-align: center;
  }

  .MuiDataGrid-root .MuiDataGrid-row:hover {
    background-color: ${colors.primaryLight.element} !important;
  }

  .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
    background-color: ${colors.secondaryLight.element} !important;
  }

  .MuiDataGrid-root .MuiDataGrid-toolbarContainer {
    justify-content: end;
  }

  .MuiDataGrid-row {
    max-height: 100% !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer;
    max-height: 100% !important;

    &--editing {
      .Mui-error {
        background-color: #ce4833;
        color: ${colors.white};
      }

      & .MuiOutlinedInput-root {
        & fieldset {
          border: none;
        }
      }
    }
  }

  .MuiDataGrid-viewport {
    max-height: unset !important;
  }

  .MuiDataGrid-renderingZone {
    max-height: unset !important;
  }

  .noPointer {
    cursor: unset;
  }

  @media (max-width: 768px) {
    .MuiDataGrid-renderingZone {
      width: 100% !important;
    }

    .MuiDataGrid-row {
      width: 100% !important;
    }

    .MuiDataGrid-cell {
      padding-top: 10px !important;
      max-width: unset !important;
      min-width: 100% !important;
      justify-content: start !important;
    }

    .MuiDataGrid-cell h5 {
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      line-height: 22px;
    }

    .MuiDataGrid-cell h5 .bold {
      font-weight: bold;
    }

    .MuiDataGrid-dataContainer {
      min-width: 100% !important;
    }

    .MuiDataGrid-viewport {
      max-height: unset !important;
    }

    .MuiDataGrid-renderingZone {
      display: contents;
    }

    .MuiDataGrid-virtualScrollerContent {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;
