import { Tabs as MUITabs, Box as MUIBox, styled as MUIStyled } from '@mui/material';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Box = MUIStyled(MUIBox)`
   border-bottom: 1px solid ${colors.black} !important;
   margin-bottom: 10px;
`;

export const Tabs = MUIStyled(MUITabs)`
  .MuiTabs-indicator {
    display: none !important;
  }

  button {
    color: ${colors.black} !important;
  }

  button:hover {
    background-color: #d3d3d3 !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  button.Mui-selected {
    border-top: 1px solid ${colors.black};
    border-left: 1px solid ${colors.black};
    border-right: 1px solid ${colors.black};
    border-bottom: none !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  @media ${bootstrapMax.sm} {
    .MuiTabs-scroller {
      display: flex;
      justify-content: center;
    }
  }
`;
