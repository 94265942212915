import styled from 'styled-components';
import { TextField } from '@mui/material';
import { colors } from '../../utils/colors';

export const Input = styled(TextField)`
  width: 100%;
`;

export const Line = styled.hr`
  background-color: ${colors.primaryDark.element};
  opacity: unset;
`;
