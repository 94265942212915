import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const MenuContext = createContext(undefined);

const MenuProvider = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleMenu = () => setShow(!show);

  return (
    <MenuContext.Provider value={{ show, handleClose, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = { children: PropTypes.node };

export { MenuContext, MenuProvider };
