import { useContext } from 'react';
import React from 'react';
import { MenuContext } from '../../context';
import { Brand, Container, HeaderItem, Nav, StyledBurger } from './styles';
import { Col, Row } from 'react-bootstrap';

export const Header = () => {
  const { toggleMenu } = useContext(MenuContext);

  const codeManager = <Brand href={'/'} />;

  return (
    <Nav>
      <Container>
        <Row>
          <Col xs={2} lg={2} className={'d-flex align-items-center justify-content-start'}>
            <StyledBurger
              onClick={() => {
                toggleMenu();
              }}
            >
              <div />
              <div />
              <div />
            </StyledBurger>
          </Col>
          <Col xs={8} lg={2} className={'d-flex align-items-center justify-content-center'}>
            {codeManager}
          </Col>
          <Col
            lg={{ span: 1, offset: 7 }}
            className={'d-none d-lg-flex align-items-center justify-content-center'}
          >
            <HeaderItem to={'/logout'}>
              <div>Sair</div>
            </HeaderItem>
          </Col>
        </Row>
      </Container>
    </Nav>
  );
};

export default Header;
