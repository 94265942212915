import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import { DataGrid, Button, Info } from '../.';
import { DefaultContext } from '../../context';
import { sortComparator } from '../../utils/helpers';
import service from '../../services/opportunityPayment';
import InputAutocomplete from '../InputAutocomplete';
import paymentTypeService from '../../services/paymentType';

const Payments = ({ rows, cellsValues, setPaymentId, setIsSelectedRow, loadData }) => {
  const [paymentTypes, setPaymentTypes] = useState([]);

  const { handleModal, setLoading } = useContext(DefaultContext);

  useEffect(() => {
    getPaymentTypes();
    if (rows?.length && !isNaN(rows[0].id)) {
      setPaymentId(rows[0].id);
      setIsSelectedRow(true);
    }
  }, [rows[0]]);

  const getPaymentTypes = async () => {
    const { ok, data: paymentTypesRes } = await paymentTypeService.list();

    if (ok && paymentTypesRes) {
      setPaymentTypes(paymentTypesRes);
    } else {
      handleModal({
        title: 'Erro inesperado',
        message: null,
        show: true,
        ok: true,
      });
    }
  };

  const handleRowClick = (e) => {
    const newRows = [...rows];
    const rowFiltered = newRows && newRows.filter((row) => row.id === e.row.id);

    if (rowFiltered.length) {
      setPaymentId(e.row.id);
      setIsSelectedRow(true);
    } else {
      setIsSelectedRow(false);
    }
  };

  const handleChange = ({ field, value, id }) => {
    let newRows = [...rows];
    const opportunityPayments = {};
    const ids = newRows.map((row) => row.id);
    const newValue = typeof value === 'string' ? value?.trim() : value;

    if (ids.indexOf(id) > -1) {
      newRows = newRows.map((row) => {
        if (row.id === id) {
          row[field] = newValue;
          !row.new && (row.edited = true);
        }
        return row;
      });
    } else {
      opportunityPayments.id = id;
      opportunityPayments.new = id;
      opportunityPayments[field] = newValue;
      opportunityPayments.amount = 0;
      opportunityPayments.opportunityInstallments = [];
    }

    const rowSelected = [...newRows, opportunityPayments].filter(
      (row) => row.id === id && (!!row.description || !!row.paymentTypeId),
    );

    cellsValues({
      target: {
        value: [...newRows, opportunityPayments].filter(
          (row) => !!row.description || !!row.paymentTypeId,
        ),
      },
    });
    setPaymentId(id);

    if (rowSelected.length) {
      setIsSelectedRow(true);
    } else {
      setIsSelectedRow(false);
    }
  };

  const handleDelete = (id) => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir esse pagamento?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        setLoading(true);
        let ok, message, load;

        if (isNaN(id)) {
          rows.map((i) => {
            if (i.id === id) i.deleted = true;
            return i;
          });
          const newRows = rows.filter((i) => !(i.new && i.deleted));
          cellsValues({ target: { value: newRows } });
          ok = true;
        } else {
          const response = await service.delete(id);
          ok = response.ok;
          message = response.message;
          load = true;
        }

        if (ok) {
          setIsSelectedRow(false);

          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          if (load) loadData();
        } else {
          handleModal({
            message,
            show: true,
          });
        }
        setLoading(false);
      },
    });
  };

  const generateInstallment = (opportunityPaymentsId, installment) => {
    const generateInstallments = ({ type, value, qtd, dueDate }) => {
      let rest = 0;
      let id = uuid();
      value = Number(value.replace('R$ ', '').replace(',', '.'));
      qtd = Number(qtd);
      dueDate = dueDate.format('YYYY-MM-DD');

      if (type === 'total') {
        rest = ((value * 100) % qtd) / 100;
        value = Number(((value - rest) / qtd).toFixed(2));
      }

      const installments = [
        { id, new: id, opportunityPaymentsId, number: 1, value: value + rest, dueDate },
      ];
      for (let i = 2; i <= qtd; i++) {
        id = uuid();
        installments.push({
          id,
          new: id,
          opportunityPaymentsId,
          number: i,
          value,
          dueDate: moment(dueDate)
            .add(i - 1, 'months')
            .format('YYYY-MM-DD'),
        });
      }

      cellsValues({
        target: {
          value: rows.map((r) => {
            if (r.id === opportunityPaymentsId) {
              if (r.opportunityInstallments.length) {
                r.opportunityInstallments = r.opportunityInstallments
                  .filter((oi) => !oi.new)
                  .map((oi) => {
                    oi.deleted = true;
                    return oi;
                  });
              }
              r.opportunityInstallments.push(...installments);
              console.log(r.opportunityInstallments);
            }
            return r;
          }),
        },
      });

      handleModal({ show: false, payment: false });
    };

    handleModal({ show: true, payment: true, generateInstallments, installment });
  };

  const generatePaymentType = (label, id) => {
    const generatePaymentTypes = async ({ label }) => {
      const paymentType = { id: null, name: label };

      const dataRes = await paymentTypeService.save(paymentType);

      const { ok, message } = dataRes;

      if (ok) {
        const newPaymentType = {
          id: dataRes.data.id,
          name: dataRes.data.name,
          label: dataRes.data.name,
        };

        if (newPaymentType) {
          paymentTypes.push(newPaymentType);

          handleChange({ field: 'paymentTypeId', value: newPaymentType.id, id });
        }
      } else {
        handleModal({ message, show: true, ok: true });
      }

      handleModal({ show: false, payment: false });
    };

    handleModal({ show: true, paymentType: true, generatePaymentTypes, value: label });
  };

  const columnsPayments = [
    {
      field: 'description',
      headerName: 'Descrição',
      flex: 0.5,
      headerAlign: 'center',
      editable: true,
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Descrição'}
            </div>
          );
        return <div tabIndex={0}>{value}</div>;
      },
    },
    {
      field: 'paymentTypeId',
      headerName: 'Tipo de pagamento',
      flex: 0.5,
      headerAlign: 'center',
      editable: true,
      type: 'number',
      align: 'center',
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined || !value)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Tipo de pagamento'}
            </div>
          );
        return (
          <div tabIndex={0}>
            {paymentTypes.length
              ? paymentTypes.find((paymentType) => paymentType.id === value).name
              : 'Tipo de pagamento'}
          </div>
        );
      },
      renderEditCell: ({ id, value, field, api }) => {
        return (
          <InputAutocomplete
            options={paymentTypes.map((pt) => {
              pt.label = pt.name;
              return pt;
            })}
            value={value ? paymentTypes.find((paymentType) => paymentType.id === value).name : ''}
            onChange={(event, params) => {
              if (params) {
                if (params.inputValue) {
                  generatePaymentType(params.inputValue, id);
                } else {
                  api.setEditCellValue({ id, field, value: params.id });
                }
              }
            }}
          />
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Total',
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      sortComparator,
      renderCell: ({ value, id }) => {
        if (value === undefined) return <div style={{ opacity: 0.4 }}>{'Total'}</div>;

        if (value !== 'R$ 0,00') {
          const opp = rows.find((r) => r.id === id);

          return (
            <Info
              text={value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              tip={'Duplo click para regerar parcelas'}
              placement={'right'}
              style={{ color: '#808080' }}
              onDoubleClick={() =>
                generateInstallment(id, {
                  value,
                  qtd: opp.opportunityInstallments.filter((oi) => !oi.deleted).length,
                  dueDate: moment(opp.opportunityInstallments[0].dueDate),
                })
              }
            />
          );
        }

        return (
          <Button
            fillCell
            tabIndex={0}
            onClick={() => generateInstallment(id)}
            text={'Gerar parcelas'}
          />
        );
      },
      cellClassName: 'noPointer',
    },
  ];

  return (
    <DataGrid
      maxSize={280}
      columns={columnsPayments}
      onDelete={(e) => handleDelete(e)}
      rows={rows}
      onEditCell={(e) => handleChange(e)}
      requiredFields={['description', 'paymentTypeId']}
      marginTop={10}
      onRowClick={(e) => handleRowClick(e)}
    />
  );
};

Payments.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  cellsValues: PropTypes.func,
  onDelete: PropTypes.func,
  onEditCell: PropTypes.func,
  onRowClick: PropTypes.func,
  setPaymentId: PropTypes.func,
  setIsSelectedRow: PropTypes.func,
  loadData: PropTypes.func,
};

export default Payments;
