import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from './styles';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const Info = ({ tip, text = '', placement = 'top', delay = { show: 250 }, ...rest }) => {
  const renderTooltip = () => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  if (text) {
    return (
      <OverlayTrigger placement={placement} delay={delay} overlay={renderTooltip()}>
        <div {...rest}>{text}</div>
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger placement={placement} delay={delay} overlay={renderTooltip()}>
      <Icon />
    </OverlayTrigger>
  );
};

Info.propTypes = {
  text: PropTypes.string,
  tip: PropTypes.string,
  delay: PropTypes.object,
  placement: PropTypes.string,
};

export default Info;
