import PropTypes from 'prop-types';
import * as React from 'react';
import { Priority } from './styles';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { priorities } from '../../enums';

const Badge = ({ priority }) => {
  const renderTooltip = (props) => {
    const { label } = props;
    return (
      <Tooltip id="button-tooltip" {...props}>
        {label}
      </Tooltip>
    );
  };

  if (priority && priority !== 'eliminated') {
    const { label, color } = priorities[priority];

    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip({ label })}
      >
        <Priority color={color} />
      </OverlayTrigger>
    );
  }

  return (
    <div
      style={{
        width: 15,
        height: 15,
        borderRadius: '100%',
      }}
    />
  );
};

Badge.propTypes = {
  priority: PropTypes.string,
  label: PropTypes.string,
};

export default Badge;
