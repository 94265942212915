import PropTypes from 'prop-types';
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = ({ value, onChange }) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_APIKEY}
      value={value ?? ''}
      onEditorChange={onChange}
      init={{
        height: 400,
        menubar: true,
        language: 'pt_BR',
        content_style: `body { font-size: 1rem; }`,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
          'image',
          'importcss',
        ],
        toolbar:
          'undo redo | formatselect | image | bold italic backcolor | ' +
          'alignleft aligncenter lignright alignjustify | ' +
          'bullist numlist outdent indent | removeformat | quote | help',
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        convert_urls: false,
        image_caption: true,
        a11y_advanced_options: true,
        // theme_advanced_buttons3_add: 'print',
      }}
    />
  );
};

Tinymce.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Tinymce;
