import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(Modal)`
  text-align: center;

  & .modal-content {
    border: 0;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
