import styled from 'styled-components';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

export const Menu = styled(Offcanvas)`
  flex-flow: column nowrap;
  background-color: ${colors.primary.element};
  width: 367px;
  transition: transform 0.3s ease-in-out;
  padding: 0 9px 0 0;

  a {
    color: ${colors.white};
    text-transform: none;
    text-decoration: none;
    font-size: 18px;
  }

  .offcanvas-body {
    padding: 1rem 1rem 1rem 2rem;
  }

  @media ${bootstrapMax.lg} {
    width: 277px;
    padding: 0 3px 0 0;

    .offcanvas-body {
      padding: 0.5rem 0.5rem 0.5rem 1rem;
    }

    a {
      font-size: 15px;
    }
  }
`;

export const MenuItemHeader = styled(NavLink)`
  color: ${colors.white} !important;
  padding: 1rem 1.25rem;
  background-color: transparent;
  font-size: 24px !important;
  outline: none;
  box-shadow: none;
  border: 1px solid ${colors.primary.element};
  transition: all ease-in-out 0.2s;

  ::before {
    content: ' ';
    width: 20px;
    height: 20px;
    margin-right: 15px;
    mask: url(${({ src }) => src}) no-repeat center;
    background-color: ${colors.white};
    transition: all ease-in-out 0.2s;
    align-self: center;
  }

  ::after {
    display: none;
  }

  &:hover,
  &:focus {
    border: 1px solid ${colors.secondary.element};
    color: ${colors.secondary.element} !important;

    ::before {
      background-color: ${colors.secondary.element};
    }
  }

  @media ${bootstrapMax.lg} {
    font-size: 18px !important;
    padding: 0.75rem;

    ::before {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
`;

export const MenuItem = styled(NavLink)`
  position: relative;
  min-width: 100%;
  padding: 15px;

  &::before {
    content: ' ';
    position: absolute;
    top: 27px;
    left: -15px;
    width: 4px;
    height: 4px;
    background-color: ${colors.white};
    border-radius: 50%;
    transition: all ease-in-out 0.2s;
  }

  &:hover {
    span {
      color: ${colors.secondary.element};
    }

    &::before {
      background-color: ${colors.secondary.element};
    }
  }

  @media ${bootstrapMax.lg} {
    padding: 10px;

    ::before {
      top: 20px;
      left: -7px;
    }
  }
`;

export const MenuLabel = styled.span`
  color: ${colors.white};
  transition: all ease-in-out 0.2s;
`;
