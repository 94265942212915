import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumb as BSBreadcrumb } from 'react-bootstrap';
import { BreadcrumbItem } from './styles';

const Breadcrumb = ({ routes }) => {
  return (
    <BSBreadcrumb>
      {routes.map(({ label, active = false, onClick }) => {
        if (active)
          return (
            <BreadcrumbItem active key={label}>
              {label}
            </BreadcrumbItem>
          );
        return (
          <BreadcrumbItem onClick={() => onClick && onClick()} key={label}>
            {label}
          </BreadcrumbItem>
        );
      })}
    </BSBreadcrumb>
  );
};

Breadcrumb.propTypes = {
  routes: PropTypes.array,
};

export default Breadcrumb;
