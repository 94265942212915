export const colors = {
  primary: { element: '#262626', text: '#f9f9f9' },
  primaryLight: { element: '#4e4e4e', text: '#f9f9f9' },
  primaryDark: { element: '#000000', text: '#f9f9f9' },
  secondary: { element: '#0b7036', text: '#f9f9f9' },
  secondaryLight: { element: '#489f61', text: '#000000' },
  secondaryDark: { element: '#00430e', text: '#f9f9f9' },
  danger: { element: '#ce4833', text: '#f9f9f9' },
  dangerDark: { element: '#960e0b', text: '#f9f9f9' },
  white: '#f9f9f9',
  black: '#000000',
  backgroundColor: '#D4D4D4FF',
};
