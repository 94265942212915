import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Input, Button, DataGrid } from '../../components';
import userService from '../../services/user';
import { DefaultContext } from '../../context';

const User = ({ match, history }) => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    phone: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const { handleModal, setLoading } = useContext(DefaultContext);
  // const [controlState, setControlState] = useState({});

  useEffect(async () => {
    const { id } = match.params;
    if (id) await getUser(id);
  }, []);

  const getUser = async (id) => {
    setLoading(true);
    const { ok, data: userRes, message } = await userService.get(id);
    // console.log(ok, userRes, message);
    if (!ok && userRes && userRes.ok && userRes.data && userRes.data.id) {
      setUser(userRes.data);
    } else {
      history.push('/usuarios');
      handleModal({ message, show: true });
    }
    return setLoading(false);
  };

  const handleChange = useCallback(
    (state, target) => {
      setUser((prev) => ({ ...prev, [target.name]: target.value }));
      // setControlState((prev) => ({ ...prev, [state]: { [target.name]: true } }));
    },
    [user],
  );

  const submit = async () => {
    // console.log(controlState);
    setLoading(true);
    const {
      ok,
      data: userRes,
      message,
    } = user.id ? await userService.edit(user) : await userService.save(user);
    if (!ok && userRes && userRes.ok && userRes.data) {
      if (user.id) {
        setUser({ ...user, newPassword: '', confirmNewPassword: '' });
      } else history.push(`/usuario/${userRes.data.id}`);
    } else handleModal({ message, show: true });
    setLoading(false);
  };

  // const states = {
  //   user,
  // };

  // const update = (obj, controlState) => {
  //   const update = {};
  //   if (controlState[obj]) {
  //     update.id = states[obj].id;
  //     Object.keys(controlState[obj]).forEach((k) => {
  //       update[k] = states[obj][k];
  //     });
  //   }
  //   return update;
  // };

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Acesso' },
    { field: 'see', headerName: 'Visualização' },
    { field: 'create', headerName: 'Inclusão' },
    { field: 'edit', headerName: 'Edição' },
    { field: 'delete', headerName: 'Exclusão' },
  ];

  const rows = [
    { id: 1, name: 'usuários', see: true, create: true, edit: false, delete: false },
    { id: 1, name: 'usuários', see: true, create: true, edit: false, delete: false },
    { id: 1, name: 'usuários', see: true, create: true, edit: false, delete: false },
    { id: 1, name: 'usuários', see: true, create: true, edit: false, delete: false },
  ];

  return (
    <>
      <Row>
        <Col align={'left'}>
          <h1>Usuário</h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className={'my-2'}>
          <Input
            required
            name="name"
            label="Nome"
            onChange={(e) => handleChange('user', e.target)}
            value={user.name || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className={'my-2'}>
          <Input
            required
            name="email"
            label="E-mail"
            onChange={(e) => handleChange('user', e.target)}
            value={user.email || ''}
          />
        </Col>
        <Col md={6} sm={12} className={'my-2'}>
          <Input
            required
            name="phone"
            label="Telefone"
            onChange={(e) => handleChange('user', e.target)}
            value={user.phone || ''}
            phone
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className={'my-2'}>
          <Input
            name="newPassword"
            label="Senha"
            onChange={(e) => handleChange('user', e.target)}
            value={user.newPassword || ''}
            password
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className={'my-2'}>
          <Input
            name="confirmNewPassword"
            label="Confirmação de Senha"
            onChange={(e) => handleChange('user', e.target)}
            value={user.confirmNewPassword || ''}
            password
          />
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Col sm={12} className={'my-2'}>*/}
      {/*    <DataGrid columns={columns} rows={rows} />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row>
        <Col sm={12} className={'my-2 d-flex justify-content-center'}>
          <Button
            text={user.id ? 'Salvar' : 'Incluir'}
            onClick={async () => await submit()}
            variant="outlined"
          />
        </Col>
      </Row>
    </>
  );
};

User.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default User;
