import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const CommentBox = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  border: 1px solid ${colors.primary.element};
  border-radius: 4px;
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
`;

export const CommentLabel = styled.div`
  position: absolute;
  top: -11px;
  left: 9px;
  font-size: 12px;
  padding: 0 6px;
  background-color: ${colors.backgroundColor};
  color: ${colors.primary.element}BB;
`;

export const CommentTitle = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 1rem 0;
  margin: 5px 0 15px;
`;

export const CommentContent = styled.div`
  width: 100%;
  font-size: 13px;
  padding: 0 1rem 10px 1rem;
  height: 100%;
  overflow-y: auto;
`;

export const CommentFooter = styled.div`
  width: 100%;
  padding: 0 1rem 10px 1rem;
`;
