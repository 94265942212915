import styled from 'styled-components';
import icon from '../../assets/icons/info-circle-solid.svg';
import { colors } from '../../utils/colors';

export const Icon = styled.div`
  content: ' ';
  mask: url(${icon}) no-repeat center;
  background-color: ${colors.primaryLight.element};
  width: 23px;
  height: 23px;
  border-radius: 50%;
`;
