import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { InputAutocomplete as Autocomplete } from './styles';
import { createFilterOptions } from '@mui/material';

export const InputAutocomplete = ({ label, onChange, value, options }) => {
  const filter = createFilterOptions({
    stringify: (option) => option.label,
  });

  return (
    <>
      <Autocomplete
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        size={'small'}
        value={value}
        onChange={onChange}
        options={options}
        renderInput={(params) => <TextField autoFocus label={label} {...params} />}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          const filtered = filter(options, params);

          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              id: 0,
              userId: 0,
              inputValue,
              name: inputValue,
              label: `Adicionar "${inputValue}"`,
            });
          }
          return filtered;
        }}
      />
    </>
  );
};

InputAutocomplete.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default InputAutocomplete;
