const axios = require('axios');
const jwt = require('jsonwebtoken');

exports.defaultStates = () => {
  const token = localStorage.getItem('token');
  const dataToken = token && jwt.decode(token);
  const decoded = dataToken ? dataToken.data && dataToken.data.user : '';

  return {
    newRegister: true,
    userLogged: decoded,
  };
};

exports.defaultModalOnHide = (that) => {
  const { modalState } = that.state;
  modalState.show = false;
  that.setState(modalState);
};

exports.axiosDefault = (type, contentType) => {
  const token = localStorage.getItem('token');
  const baseURL = this.baseURL();

  const configs = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
      withCredentials: true,
      baseURL: baseURL,
    },
    withCredentials: true,
  };

  if (type === 'download') configs.headers['Content-Type'] = contentType;

  return axios.create(configs);
};

exports.baseURL = () => {
  if (process.env.REACT_APP_DEPLOYING_PRODUCTION === 'true') {
    return process.env.REACT_APP_PROD_SERVER_URL;
  } else if (process.env.REACT_APP_DEPLOYING_HOMOLOGATION === 'true') {
    return process.env.REACT_APP_HOMOLOG_SERVER_URL;
  }

  return window.location.origin;
};

exports.handleErrors = (error) => {
  if (error.response && error.response.data && error.response.data.log) {
    console.error('Erro: ', error.response.data.log);
  }

  if (error.response && error.response.data && error.response.data.logout) {
    window.location.href = '/logout';
  }

  const statusCode = (error && error.response && error.response.status) || null;

  let data = {};
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      data = error.response.data;
    } else {
      data.message = 'Erro inesperado';
    }
    data.statusCode = statusCode;
  }

  return data || { ok: false, message: 'Erro inesperado', statusCode };
};
