import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { Container, ModalHeader, ModalBody, ModalFooter } from './styles';
import { Row, Col } from 'react-bootstrap';
import { Input, Button } from '..';

const PaymentTypes = ({ show, onHide, handleModal, generatePaymentTypes, value }) => {
  const [paymentTypes, setPaymentTypes] = useState({
    id: null,
    label: value,
    name: value,
  });

  const [disabledUseEffect, setDisabledUseEffect] = useState(false);

  useEffect(() => {
    if (!disabledUseEffect) {
      setPaymentTypes({ label: value, name: value });
    }
  }, [value !== paymentTypes.label, disabledUseEffect]);

  const handleChange = useCallback((field, target) => {
    setPaymentTypes((prev) => ({ ...prev, [field]: target.value }));
  }, []);

  return (
    <Container show={show} size="md" centered onHide={onHide}>
      <ModalHeader closeButton>
        <Row>
          <Col className={'mt-3'} align={'left'}>
            <h3>Tipo de pagamento</h3>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row className={'mb-3'}>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <Input
              value={paymentTypes.label}
              onChange={(e) => {
                setDisabledUseEffect(true);
                handleChange('label', e.target);
              }}
              label={'Tipo de pagamento'}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className={'mb-2'} xs={12} md={6}>
            <Button
              text={'Cancelar'}
              secondary
              variant="outlined"
              onClick={() => {
                setDisabledUseEffect(false);
                handleModal({ show: false });
              }}
            />
          </Col>
          <Col className={'mb-2'} xs={12} md={6}>
            <Button
              text={'Salvar'}
              variant="outlined"
              onClick={() => {
                generatePaymentTypes(paymentTypes);
                setDisabledUseEffect(false);
              }}
              disabled={paymentTypes.label === ''}
            />
          </Col>
        </Row>
      </ModalFooter>
    </Container>
  );
};

PaymentTypes.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  handleModal: PropTypes.func,
  generatePaymentTypes: PropTypes.func,
  value: PropTypes.string,
};

export default PaymentTypes;
