import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { Container, ModalHeader, ModalBody, ModalFooter } from './styles';
import { Row, Col } from 'react-bootstrap';
import { Input, Button, Calendar, Radio } from '../.';

const Installments = ({
  show,
  onHide,
  handleModal,
  generateInstallments,
  installment: prevInstallment,
}) => {
  const [installment, setInstallment] = useState({
    value: '',
    qtd: '',
    dueDate: '',
    type: 'total',
  });

  useEffect(() => {
    setInstallment((prev) => ({ ...prev, ...prevInstallment }));
  }, [prevInstallment]);

  const handleChange = useCallback(
    (field, target) => {
      setInstallment((prev) => ({ ...prev, [field]: target.value }));
    },
    [installment],
  );

  const listOptions = [
    { value: 'total', label: 'Total do pagamento' },
    { value: 'unique', label: 'Por parcela' },
  ];

  return (
    <Container show={show} size="md" centered onHide={onHide}>
      <ModalHeader closeButton>
        <Row>
          <Col className={'mt-3'} align={'left'}>
            <h3>Parcelamento</h3>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row className={'mb-3'}>
          <Col xs={12} md={{ span: 8, offset: 2 }} className={'d-flex justify-content-start'}>
            <Radio
              label={'Valor a inserir:'}
              list={listOptions}
              value={installment.type}
              onChange={(e) => handleChange('type', e.target)}
            />
          </Col>
        </Row>
        <Row className={'mb-3'}>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <Input
              value={installment.value}
              onChange={(e) => handleChange('value', e.target)}
              label={`Valor ${
                installment.type
                  ? listOptions.find((l) => l.value === installment.type).label.toLowerCase()
                  : ''
              }`}
              currency
            />
          </Col>
        </Row>
        <Row className={'mb-3'}>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <Input
              label="Quantidade de parcelas"
              number
              defaultValue="Insira um número"
              value={installment.qtd}
              onChange={(e) => handleChange('qtd', e.target)}
            />
          </Col>
        </Row>
        <Row className={'mb-3'}>
          <Col xs={12} md={{ span: 8, offset: 2 }}>
            <Calendar
              label={'Data 1º vencimento'}
              value={installment.dueDate}
              onChange={(e) => handleChange('dueDate', e.target)}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className={'mb-2'} xs={12} md={6}>
            <Button
              text={'Cancelar'}
              secondary
              variant="outlined"
              onClick={() => {
                handleModal({ show: false });
              }}
            />
          </Col>
          <Col className={'mb-2'} xs={12} md={6}>
            <Button
              text={'Gerar'}
              variant="outlined"
              onClick={() => generateInstallments(installment)}
              disabled={
                installment.value === '' ||
                installment.qtd === '' ||
                installment.dueDate === '' ||
                (installment.dueDate && !installment.dueDate.isValid())
              }
            />
          </Col>
        </Row>
      </ModalFooter>
    </Container>
  );
};

Installments.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  handleModal: PropTypes.func,
  generateInstallments: PropTypes.func,
  installment: PropTypes.object,
};

export default Installments;
