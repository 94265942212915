import { axiosDefault, handleErrors } from '../utils/defaults';

const paymentType = {
  list: async () => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/paymentTypes`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  save: async (paymentType) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.post(`/api/paymentTypes`, paymentType);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default paymentType;
