import React from 'react';
import InputMask from 'react-input-mask';
import {
  StyleTextField as TextField,
  StyleNumberFormat,
  FocalPointComment,
  StyleTextFieldComment,
  HeaderComment,
  SearchInput,
  SearchIcon,
} from './styles';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';

export const Input = ({
  label,
  name,
  number,
  value,
  placeholder,
  onChange,
  onClick,
  variant,
  password,
  textArea,
  comment,
  commentContactDesc,
  rows,
  step,
  error,
  disabled,
  search,
  action,
  phone,
  currency,
}) => {
  if (currency) {
    return (
      <StyleNumberFormat
        customInput={TextField}
        thousandsGroupStyle="thousand"
        prefix="R$ "
        decimalSeparator=","
        displayType="input"
        type="text"
        size={'small'}
        thousandSeparator={false}
        allowNegative={true}
        decimalScale={2}
        fixedDecimalScale={true}
        isNumericString={false}
        value={value}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
      />
    );
  }
  if (number) {
    return (
      <TextField
        inputProps={{
          step: step,
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        id={name}
        name={name}
        label={label}
        value={value}
        placeholder={placeholder}
        type="text"
        onChange={(e) => !isNaN(e.target.value) && onChange(e)}
        variant={variant ?? 'outlined'}
        size={'small'}
        error={error}
      />
    );
  }

  if (password) {
    return (
      <TextField
        id={name}
        name={name}
        label={label}
        value={value}
        placeholder={placeholder}
        type={'password'}
        onChange={onChange}
        variant={variant ?? 'outlined'}
        autoComplete="current-password"
        size={'small'}
        error={error}
      />
    );
  }

  if (textArea) {
    return (
      <TextField
        id={name}
        name={name}
        label={label}
        value={value ?? ''}
        multiline
        rows={rows}
        placeholder={placeholder}
        onChange={onChange}
        variant={variant ?? 'outlined'}
        error={error}
      />
    );
  }

  if (comment) {
    return (
      <div className={'position-relative mt-2'}>
        <HeaderComment className={'px-2 mt-1'}>
          <FocalPointComment>{commentContactDesc}</FocalPointComment>
        </HeaderComment>
        <StyleTextFieldComment
          id={name}
          name={name}
          label={label}
          value={value}
          multiline
          rows={rows}
          placeholder={placeholder}
          onChange={onChange}
          variant={variant ?? 'outlined'}
          error={error}
        />
      </div>
    );
  }

  if (search)
    return (
      <SearchInput>
        <SearchIcon onClick={onClick} />
        <TextField
          id={name}
          name={name}
          label={label}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          variant={variant ?? 'outlined'}
          size={'small'}
          disabled={disabled}
          onKeyDown={(e) => action && action(e.code)}
          search="true"
        />
      </SearchInput>
    );

  if (phone)
    return (
      <InputMask
        mask={
          value && value.replaceAll('_', '').length < 15 ? '(99) 9999-99999' : '(99) 99999-9999'
        }
        id={name}
        name={name}
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          e.target.value = e.target.value.replaceAll('_', '');
          onChange(e);
        }}
        variant={variant ?? 'outlined'}
        size={'small'}
        disabled={disabled}
      >
        {(inputProps) => <TextField {...inputProps} />}
      </InputMask>
    );

  return (
    <TextField
      id={name}
      name={name}
      label={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      variant={variant ?? 'outlined'}
      size={'small'}
      disabled={disabled}
      onKeyDown={(e) => action && action(e.code)}
    />
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  password: PropTypes.bool,
  number: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  variant: PropTypes.array,
  textArea: PropTypes.bool,
  comment: PropTypes.bool,
  commentContactDesc: PropTypes.string,
  rows: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  search: PropTypes.bool,
  action: PropTypes.func,
  phone: PropTypes.bool,
  currency: PropTypes.bool,
};

export default Input;
