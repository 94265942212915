import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Calendar, DataGrid } from '../.';
import { DefaultContext } from '../../context';
import { sortComparator } from '../../utils/helpers';
import service from '../../services/opportunityInstallment';
import { GridOverlay } from '@mui/x-data-grid';
import { ReactComponent as SelectionIcon } from '../../assets/icons/selection.svg';
import { Icon } from './styles';
import Input from '../Input';

const Installments = ({ rows, paymentId, setPayments, loadData, isSelectedRow }) => {
  const { handleModal, setLoading } = useContext(DefaultContext);

  const handleChange = (e) => {
    const { field, value, id } = e;
    let newRows = [...rows];
    const opportunityInstallments = {};
    const ids = newRows.map((row) => row.id);

    if (ids.indexOf(id) > -1) {
      newRows.map((row) => {
        if (row.id === id) {
          row[field] = value;
          !row.new && (row.edited = true);
        }
        return row;
      });
    } else {
      opportunityInstallments.id = id;
      opportunityInstallments.new = id;
      opportunityInstallments.number =
        (rows.length ? Number(rows[rows.length - 1]?.number) : 0) + 1;
      opportunityInstallments.opportunityPaymentsId = paymentId;
      opportunityInstallments[field] = value;
    }
    setPayments(opportunityInstallments, paymentId);
  };

  const handleDelete = (id) => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir essa parcela?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        setLoading(true);
        let ok, message, load;

        if (isNaN(id)) {
          rows.map((i) => {
            if (i.id === id) i.deleted = true;
            return i;
          });
          const newRows = rows.filter((i) => !(i.new && i.deleted));
          setPayments(newRows, paymentId);
          ok = true;
        } else {
          const response = await service.delete(id);
          ok = response.ok;
          message = response.message;
          load = true;
        }

        if (ok) {
          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          if (load) loadData();
        } else {
          handleModal({
            message,
            show: true,
          });
        }
        setLoading(false);
      },
    });
  };

  const columnsInstallments = [
    {
      field: 'number',
      headerName: 'N.º parcela',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'N.º parcela'}
            </div>
          );
        return <div tabIndex={0}>{value}</div>;
      },
      cellClassName: 'noPointer',
    },
    {
      field: 'value',
      headerName: 'Valor',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: true,
      sortComparator,
      renderCell: ({ value }) => {
        if (!value)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Valor'}
            </div>
          );
        return (
          <div tabIndex={0}>
            {value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </div>
        );
      },
      renderEditCell: ({ id, value, field, api }) => {
        return (
          <Input
            value={value}
            onChange={async ({ target: { value } }) =>
              await api.setEditCellValue({ id, field, value })
            }
            currency
          />
        );
      },
    },
    {
      field: 'dueDate',
      headerName: 'Vencimento',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      editable: true,
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Vencimento'}
            </div>
          );
        return <div tabIndex={0}>{moment(value).format('DD/MM/YYYY')}</div>;
      },
      renderEditCell: ({ api, id, value, field }) => {
        return (
          <Calendar
            autoFocus
            onChange={({ target: { value } }) => api.setEditCellValue({ id, field, value })}
            value={value}
          />
        );
      },
      preProcessEditCellProps: ({ props: { value } }) => {
        return { value, error: value && !moment(value).isValid() };
      },
    },
  ];

  const noRowsOverlayCustom = () => {
    return (
      <>
        <GridOverlay sx={{ mt: 1 }} className={'flex-column'}>
          <Icon component={SelectionIcon} />
          Selecione um pagamento
        </GridOverlay>
      </>
    );
  };

  return (
    <DataGrid
      maxSize={280}
      columns={columnsInstallments}
      onDelete={(e) => handleDelete(e)}
      rows={rows}
      onEditCell={(e) => handleChange(e)}
      requiredFields={['number', 'value', 'dueDate']}
      marginTop={10}
      noRowsOverlayCustom={noRowsOverlayCustom()}
      isSelectedRow={isSelectedRow}
      isDependent={true}
    />
  );
};

Installments.propTypes = {
  paymentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.array,
  rows: PropTypes.array,
  onDelete: PropTypes.func,
  onEditCell: PropTypes.func,
  setPayments: PropTypes.func,
  loadData: PropTypes.func,
  isSelectedRow: PropTypes.bool,
};

export default Installments;
