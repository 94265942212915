import { axiosDefault, handleErrors } from '../utils/defaults';

const opportunityProduct = {
  delete: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.delete(`/api/opportunityProducts/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default opportunityProduct;
