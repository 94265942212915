import PropTypes from 'prop-types';
import React from 'react';
import { Container } from './styles';

function Body({ children }) {
  return <Container>{children}</Container>;
}

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;
