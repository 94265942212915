import React from 'react';
import Router from './pages/Router/';
import CssBaseline from '@mui/material/CssBaseline';

import './styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <main>
      <CssBaseline />
      <Router />
    </main>
  );
}

export default App;
