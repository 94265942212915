import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import OpportunityProducts from './opportunityProducts';
import OpportunityProductComponents from './opportunityProductComponents';
import { TotalHours } from './styles';

const Estimate = ({ rows, cellsValues, setProducts, getTotalEstimatedHours, loadData }) => {
  const [productId, setProductId] = useState('');
  const [isSelectedRow, setIsSelectedRow] = useState(false);

  const productComponentFiltered = rows.filter((product) => product.id === productId);
  const productComponent = productComponentFiltered.length
    ? productComponentFiltered[0].opportunityProductComponents
    : [];

  const totalEstimatedHours = getTotalEstimatedHours();

  return (
    <>
      <Row className={'mt-4 mb-3'}>
        <Col align={'left'}>
          <h3>Estimativa</h3>
        </Col>
      </Row>
      <Row>
        <Col className={`col-12 col-md-6 mb-4`}>
          <OpportunityProducts
            rows={rows}
            cellsValues={cellsValues}
            setProductId={setProductId}
            getTotalEstimatedHours={getTotalEstimatedHours}
            loadData={loadData}
            setIsSelectedRow={setIsSelectedRow}
          />
        </Col>
        <Col className={`col-12 col-md-6 mb-4`}>
          <OpportunityProductComponents
            rows={productComponent}
            cellsValues={cellsValues}
            productId={productId}
            setProducts={setProducts}
            getTotalEstimatedHours={getTotalEstimatedHours}
            loadData={loadData}
            isSelectedRow={isSelectedRow}
          />
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col xs={12} className={'d-flex justify-content-end'}>
          <TotalHours>
            <span>Estimativa total:</span>
            <span style={{ marginLeft: '1.5rem' }}>
              {totalEstimatedHours
                ? `${totalEstimatedHours.toLocaleString('pt-br')} hora${
                    totalEstimatedHours > 1 ? 's' : ''
                  }`
                : '--'}
            </span>
          </TotalHours>
        </Col>
      </Row>
    </>
  );
};

Estimate.propTypes = {
  rows: PropTypes.array,
  cellsValues: PropTypes.func,
  setProducts: PropTypes.func,
  getTotalEstimatedHours: PropTypes.func,
  loadData: PropTypes.func,
};

export default Estimate;
