import { DatePicker as MUIDatePicker } from '@mui/lab/';
import { TextField as MUITextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../utils/colors';

export const DatePicker = styled(MUIDatePicker)`
  .MuiOutlinedInput-root:hover {
    border: 1px solid red !important;
  }
`;

export const TextField = styled(MUITextField)(({ error }) => ({
  'label.Mui-focused': {
    color: `${!error ? `${colors.primary.element} !important` : ''}`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.primary.element,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${!error ? colors.primary.element : ''}`,
    },
    '&:hover fieldset': {
      borderColor: `${!error ? colors.primary.element : ''}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${!error ? colors.primary.element : ''}`,
    },
  },
  width: '100%',
}));
