import React, { useContext, useEffect, useState } from 'react';
import { ECharts } from '../../components';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import dashboardService from '../../services/dashboard';
import { DefaultContext } from '../../context';
import { contactTypes } from '../../enums';

const Dashboard = () => {
  const { setLoading, handleModal } = useContext(DefaultContext);
  const [contactsGraph, setContactsGraph] = useState({});

  useEffect(async () => {
    await getContactsGraph();
  }, []);

  const getContactsGraph = async () => {
    setLoading(true);

    const { data, ok, message } = await dashboardService.contactsGraph();

    const graphType = 'bar';

    const contactTypeNames = Object();

    Object.values(contactTypes).map((value) => {
      contactTypeNames[value.label] = value.color;
    });

    // Insert series data
    for (let i = 1; i < data.dataset.dimensions.length; i++) {
      const content = {
        type: graphType,
      };

      if (Object.keys(contactTypeNames).includes(data.dataset.dimensions[i])) {
        content.stack = 'Contact';

        content.tooltip = {
          formatter: (data) => {
            let legend = `<div>Contatos</div>`;
            let total = 0;

            for (let prop in data.value) {
              if (Object.keys(contactTypeNames).includes(prop)) {
                legend += `<div style="width: 150px;"><span style="display:inline-block; margin-right:4px; border-radius:10px; width:10px; height:10px; background-color:${contactTypeNames[prop]};"></span>${prop}<strong class="text-right float-end">${data.value[prop]}</strong></div>`;
                total += data.value[prop];
              }
            }

            legend += `<div style="width: 150px; padding-left: 14px;"><strong>Total <span class="text-right float-end">${total}</span></strong></div>`;

            return legend;
          },
        };

        content.itemStyle = {
          color: contactTypeNames[data.dataset.dimensions[i]],
        };
      } else {
        content.stack = '';
      }

      data.series.push(content);
    }

    if (data && ok) {
      setContactsGraph(data);
    } else {
      handleModal({
        title: 'Informação',
        message: message,
        show: true,
      });
    }

    return setLoading(false);
  };

  const options = {
    title: {
      text: 'Contatos realizados por período',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      padding: [30, 0, 0, 0],
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value',
    },
    dataset: contactsGraph.dataset,
    series: contactsGraph.series,
  };

  // Eventual filter

  /* const getLeads = async () => {
    setLoading(true);
    const data = await leadService.list();

    if (data && data.ok && data.data) {
      setLeads(data.data);
    } else {
      setModal({
        ...modal,
        title: 'Informação',
        message: data.message,
        show: true,
      });
    }

    return setLoading(false);
  };

  const handleChangeMultipleSelect = (event, set) => {
    const { target: { value } } = event;
    set(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeCalendar = (event, set) => {

  };

  const filters = () => {
    return (
      <Paper>
        <Row>
          <Col>
            <Typography
              ml={2}
              mt={1}
              variant="body1">
              Filtros
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <MultipleSelect
              label={'Leads'}
              options={leads.map((lead) => ({ label: lead.name, value: lead.id }))}
              value={filteredLeads}
              onChange={(event) => handleChangeMultipleSelect(event, setFilteredLeads)}
            />
          </Col>
          <Col sm={12} md={3} className={'mt-3'}>
            <Calendar
              onChange={(event) => handleChangeCalendar(event, setCalendarFilter)}
              value={calendarFilter}
            />
          </Col>
          <Col sm={12} md={3} className={'mt-3'}>
            <Button onClick={() => { }} text={'Filtrar'} />
          </Col>
        </Row>
      </Paper>
    );
  }; */

  return (
    <>
      {/* <Row>
        <Col>{filters()}</Col>
      </Row> */}
      <Row>
        <Col>
          <ECharts options={options} />
        </Col>
      </Row>
    </>
  );
};

Dashboard.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Dashboard;
