import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { Container, ModalHeader, ModalBody, ModalFooter } from './styles';
import { Row, Col } from 'react-bootstrap';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Input, Select, Button, Calendar, Text } from '../.';
import moment from 'moment';
import { checkChanges } from '../../utils/helpers';

const ContactModal = ({
  show,
  onHide,
  entityId,
  newRegister,
  contactId,
  contactTypes,
  entityType,
  focalPoints: fp,
  service,
  loadingDataFunc,
  minContactDate,
  handleModal,
  contactDataBefore,
}) => {
  const today = moment().startOf('day');

  const contactInitialState = {
    new: false,
    edited: false,
    deleted: false,
    contactDate: today,
    comment: '',
    contactType: '',
    nextContactDate: '',
    noNextContact: false,
    contactDesc: '',
    user: '',
    focalPoint: '',
  };

  const [contact, setContact] = useState(contactInitialState);
  const [stateBeforeUpdate, setStateBeforeUpdate] = useState(contact);
  const [actualContact, setActualContact] = useState('');
  const [focalPoints, setFocalPoints] = useState([]);
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState('');
  const [contactTypesFocalPoint, setContactTypesFocalPoint] = useState({});

  useEffect(async () => {
    setMessage('');
    if (newRegister) {
      if (contactDataBefore) {
        setContact(contactDataBefore);
      } else {
        setContact({ ...contactInitialState, [`${entityType}Id`]: entityId });
        setStateBeforeUpdate({ ...contactInitialState, [`${entityType}Id`]: entityId });
      }
      setFocalPoints(fp);
    } else {
      const editContact = await service.getContact(contactId);
      if (editContact.data && editContact.data.id) {
        setContact((state) => ({ ...state, ...editContact.data, edited: true }));
        setStateBeforeUpdate((state) => ({ ...state, ...editContact.data, edited: true }));
        setActualContact(editContact.data.contactDate);
      }

      const fps = await service.getFocalPoints(entityId);
      if (fps.data && fps.data.length) setFocalPoints(fps.data);
    }
  }, [contactId, newRegister]);

  useEffect(() => {
    if (!contactDataBefore) {
      setContact((state) => ({
        ...state,
        focalPoint: contact.focalPoint
          ? focalPoints.find((f) => {
              if (typeof contact.focalPoint === 'string') return f.name === contact.focalPoint;
              return f.name === contact.focalPoint.name;
            })
          : '',
      }));

      setStateBeforeUpdate((state) => ({
        ...state,
        focalPoint: contact.focalPoint
          ? focalPoints.find((f) => {
              if (typeof contact.focalPoint === 'string') return f.name === contact.focalPoint;
              return f.name === contact.focalPoint.name;
            })
          : '',
      }));
    }
  }, [focalPoints]);

  useEffect(() => {
    filterContactTypes();
  }, [contact.focalPoint]);

  const handleContact = useCallback(
    (state, target) => {
      setContact((prev) => ({ ...prev, [state]: target.value }));
    },
    [contact],
  );

  const filterContactTypes = () => {
    const newContactTypes = { ...contactTypes };
    if (!(contact.focalPoint && contact.focalPoint.phone)) {
      delete newContactTypes['call'];
      delete newContactTypes['whatsapp'];
    }
    if (!(contact.focalPoint && contact.focalPoint.email)) {
      delete newContactTypes['email'];
    }

    setContactTypesFocalPoint(newContactTypes);
  };

  minContactDate = moment(newRegister ? minContactDate : actualContact).startOf('day');
  const minNextContactDate = moment(contact.contactDate).startOf('day');

  const validadeAndSubmitContact = async () => {
    //TODO: sempre resetar o validated pra false
    setValidated(true);
    contact.minContactDate = minContactDate;
    contact.minNextContactDate = minNextContactDate;
    stateBeforeUpdate.minContactDate = minContactDate;
    stateBeforeUpdate.minNextContactDate = minNextContactDate;

    if (contact.edited) {
      const { ok, data, message } = await service.editContact(contact);

      if (ok && data) {
        setValidated(false);
        setContact(contactInitialState);
        setStateBeforeUpdate(contactInitialState);

        handleModal({
          title: 'Contato alterado com sucesso!',
          message: null,
          show: true,
          ok: true,
        });
      } else {
        setMessage(message);
      }
    } else {
      contact.new = true;
      stateBeforeUpdate.new = true;
      const { ok, data, message } = await service.saveContact(entityId, contact);
      if (ok && data) {
        setValidated(false);
        setContact(contactInitialState);
        setStateBeforeUpdate(contactInitialState);

        handleModal({
          title: 'Contato registrado com sucesso!',
          message: null,
          show: true,
          ok: true,
        });
      } else {
        setMessage(message);
      }
    }
    loadingDataFunc();
  };

  const cancel = () => {
    if (checkChanges(stateBeforeUpdate, contact)) {
      handleModal({ show: false });
    } else {
      handleModal({
        title: 'Atenção',
        message: 'Existem alterações nesse registro, tem certeza que deseja cancelar?',
        show: true,
        confirm: true,
        confirmText: 'Sim',
        confirmDanger: true,
        cancel: true,
        cancelText: 'Cancelar',
        noCloseButton: true,
        onHide: () => {},
        onConfirm: () => {
          handleModal({
            show: false,
          });
        },
        onCancel: async () => {
          await handleModal({
            show: true,
            entityType,
            entityId: entityId,
            contactTypes,
            newRegister: true,
            focalPoints: focalPoints,
            service,
            loadingDataFunc,
            minContactDate: minContactDate,
            contactDataBefore: contact,
          });
        },
      });
    }
  };

  return (
    <Container show={show} size="lg" centered onHide={cancel}>
      <ModalHeader closeButton>
        <Row>
          <Col className={'mt-3'} align={'left'}>
            <h3>Contato</h3>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} md={6}>
            <Row className={'mb-3'}>
              <Col sm={12} md={12}>
                <Select
                  labelId={'Focal Point'}
                  label={'Ponto Focal'}
                  minWidth={'100%'}
                  array={focalPoints}
                  value={contact.focalPoint}
                  onChange={(e) => {
                    handleContact('focalPoint', e.target);
                  }}
                  error={validated && !contact.focalPoint}
                />
              </Col>
            </Row>
            <Row className={'mb-3'}>
              <Col sm={12} md={12}>
                <Calendar
                  label={'Data do contato'}
                  value={contact.contactDate}
                  onChange={(e) => handleContact('contactDate', e.target)}
                  error={validated && !contact.contactDate}
                  maxDate={today}
                  minDate={minContactDate}
                />
              </Col>
            </Row>
            <Row className={'mb-3'}>
              <Col sm={12} md={12}>
                <Select
                  labelId={'way'}
                  label={'Meio'}
                  minWidth={'100%'}
                  Obj={contactTypesFocalPoint}
                  value={contact.contactType}
                  onChange={(e) => {
                    switch (e.target.value) {
                      case 'email':
                        handleContact('contactDesc', { value: contact.focalPoint.email });
                        break;
                      case 'call':
                        handleContact('contactDesc', { value: contact.focalPoint.phone });
                        break;
                      case 'whatsapp':
                        handleContact('contactDesc', { value: contact.focalPoint.phone });
                        break;
                      case 'meet':
                        handleContact('contactDesc', { value: '' });
                        break;
                    }
                    handleContact('contactType', e.target);
                  }}
                  error={validated && !contact.contactType}
                />
              </Col>
            </Row>
            <Row className={'mb-3'}>
              <Col sm={12} md={12}>
                <Calendar
                  label={'Próximo contato'}
                  value={contact.nextContactDate}
                  onChange={(e) => handleContact('nextContactDate', e.target)}
                  disabled={contact.noNextContact}
                  error={validated && !contact.nextContactDate}
                  minDate={minNextContactDate}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={'auto'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contact.noNextContact}
                      onChange={(e) => {
                        setContact((prev) => ({
                          ...prev,
                          noNextContact: e.target.checked,
                          nextContactDate: null,
                        }));
                      }}
                    />
                  }
                  label="Não haverá próximo contato"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col>
                <Input
                  label="Comentário"
                  textArea
                  multiline
                  rows={11}
                  fullWidth
                  defaultValue="Insira um comentário"
                  value={contact.comment}
                  error={validated && !contact.comment}
                  onChange={(e) => handleContact('comment', e.target)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>{message && <Text text={message} warning />}</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col className={'mt-2'} sm={12} md={6}>
            <Button text={'Cancelar'} secondary variant="outlined" onClick={() => cancel()} />
          </Col>
          <Col className={'mt-2'} sm={12} md={6}>
            <Button
              text={'Salvar'}
              variant="outlined"
              onClick={async () => {
                await validadeAndSubmitContact();
              }}
            />
          </Col>
        </Row>
      </ModalFooter>
    </Container>
  );
};

ContactModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  entityId: PropTypes.number,
  newRegister: PropTypes.bool,
  contactId: PropTypes.number,
  contactTypes: PropTypes.object,
  entityType: PropTypes.string,
  focalPoints: PropTypes.array,
  service: PropTypes.object,
  loadingDataFunc: PropTypes.func,
  minContactDate: PropTypes.any,
  handleModal: PropTypes.func,
  contactDataBefore: PropTypes.object,
};

export default ContactModal;
