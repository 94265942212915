import PropTypes from 'prop-types';
import { useState } from 'react';
import * as React from 'react';
import { DatePicker, TextField } from './styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';
import moment from 'moment';

const Calendar = ({ label, readOnly, disabled, minDate, maxDate, value, onChange, autoFocus }) => {
  const [hasError, setHasError] = useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        mask={'__/__/____'}
        inputFormat="DD/MM/YYYY"
        label={label}
        value={value ? moment(value).startOf('day') : null}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => onChange({ target: { value: value?.startOf('day') } })}
        renderInput={(params) => (
          <TextField
            autoFocus={autoFocus}
            onBlur={(e) => {
              if (hasError) e.target.focus();
            }}
            {...params}
            size={'small'}
          />
        )}
        readOnly={readOnly}
        disabled={disabled}
        onError={(e) => setHasError(e)}
      />
    </LocalizationProvider>
  );
};

Calendar.propTypes = {
  label: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default Calendar;
