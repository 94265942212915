import React, { useContext } from 'react';
import { DefaultContext } from '../../context';
import ContactModal from './contact';
import Installments from './installments';
import PaymentTypes from './paymentTypes';
import { Container, ModalHeader, ModalBody, ModalFooter } from './styles';
import { Button } from '../.';

function Modal() {
  let {
    modal: {
      show,
      title,
      message,
      onHide,
      ok,
      okText,
      okDanger,
      onOk,
      confirm,
      confirmText,
      confirmDanger,
      onConfirm,
      cancel,
      cancelText,
      cancelDanger,
      onCancel,
      entityId,
      payment,
      generateInstallments,
      paymentType,
      generatePaymentTypes,
      value,
      installment,
      contactDataBefore,
      noCloseButton,
      ...contact
    },
    handleModal,
  } = useContext(DefaultContext);

  if (entityId) {
    return (
      <ContactModal
        show={show}
        onHide={onHide}
        entityId={entityId}
        handleModal={handleModal}
        contactDataBefore={contactDataBefore}
        {...contact}
      />
    );
  }

  if (payment) {
    return (
      <Installments
        show={show}
        onHide={onHide}
        handleModal={handleModal}
        generateInstallments={generateInstallments}
        installment={installment}
      />
    );
  }

  if (paymentType) {
    return (
      <PaymentTypes
        show={show}
        onHide={onHide}
        handleModal={handleModal}
        generatePaymentTypes={generatePaymentTypes}
        value={value}
      />
    );
  }

  return (
    <Container show={show} size="lg" centered onHide={onHide}>
      <ModalHeader closeButton={!noCloseButton} />
      <ModalBody>
        <h4>{title}</h4>
        {message ? typeof message === 'string' ? <span>{message}</span> : message : ''}
      </ModalBody>
      <ModalFooter>
        {ok && (
          <div className="m-3">
            <Button danger={okDanger} text={okText} variant={'primary'} onClick={() => onOk()} />
          </div>
        )}
        {confirm && (
          <div className="m-3">
            <Button
              danger={confirmDanger}
              text={confirmText}
              variant={'primary'}
              onClick={() => onConfirm()}
            />
          </div>
        )}
        {cancel && (
          <div className="m-3">
            <Button
              danger={cancelDanger}
              text={cancelText}
              variant={'primary'}
              onClick={() => onCancel()}
            />
          </div>
        )}
      </ModalFooter>
    </Container>
  );
}

export default Modal;
