const moment = require('moment');

exports.getLabelFromEnum = (value, typeEnum) => {
  return Object.keys(typeEnum).map((k) => {
    if (k === value) {
      return typeEnum[k].label;
    }
  });
};

exports.getEnumFromLabel = (value, typeEnum) => {
  return Object.values(typeEnum).map((k) => {
    if (k.label === value) {
      return typeEnum[k].value;
    }
  });
};

exports.checkChanges = (...objects) => {
  return objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));
};

exports.sortComparator = (v1, v2, { api: { getSortModel } }) => {
  const { sort } = getSortModel()[0];
  if ((v1 === undefined && sort === 'asc') || (v2 && v1 === null) || (v1 && v2 && v1 > v2))
    return 1;
  return -1;
};

exports.sortComparatorByDate = (v1, v2) => {
  if (moment(v1).isValid()) {
    if (moment(v2).isValid()) {
      if (moment(v1).isAfter(moment(v2))) return 1;
      if (moment(v1).isBefore(moment(v2))) return -1;
      else return 0;
    } else return 1;
  }
  if (moment(v2).isValid()) return -1;
  else return -1;
};
