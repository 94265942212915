import React, { useCallback, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import {
  Select,
  Text,
  Input,
  FocalPoints,
  Button,
  Calendar,
  Tabs,
  Tab,
  Contacts,
  Breadcrumb,
  Estimate,
  CommentBox,
  Accordion,
  OpportunityPayments,
  Tinymce,
} from '../../components';
import { opportunityStatus, opportunityStage } from '../../enums';
import opportunityService from '../../services/opportunity';
import { DefaultContext } from '../../context';
import moment from 'moment';
import { checkChanges } from '../../utils/helpers';

const Opportunity = ({ match, history }) => {
  const today = moment().startOf('day');
  const { setLoading, handleModal } = useContext(DefaultContext);

  const [opportunity, setOpportunity] = useState({
    id: null,
    name: '',
    includedAt: today,
    stage: 0,
    expectation: '',
    status: '',
    observation: '',
    description: '',
    focalPoints: [],
    contacts: [],
    opportunityProducts: [],
    opportunityPayments: [],
  });

  const [stateBeforeUpdate, setStateBeforeUpdate] = useState(opportunity);
  const [valueTab, setValueTab] = useState(0);
  const [lastContact, setLastContact] = useState(opportunity.contacts[0]);

  useEffect(async () => {
    const { id } = match.params;
    if (id) {
      await getOpportunity(id);
    }
  }, []);

  const setProducts = (component, id) => {
    const { opportunityProducts } = opportunity;

    if (Object.keys(component).length && (component.name || component.estimatedHours)) {
      opportunityProducts.map((product) => {
        if (product.id === id) {
          const hasComponent = product.opportunityProductComponents.filter(
            (productComponent) => productComponent.id === component.id,
          ).length;
          if (hasComponent) {
            product.opportunityProductComponents = product.opportunityProductComponents.map(
              (prodComponent) => {
                if (prodComponent === component.id) {
                  return { ...prodComponent, ...component };
                }
                return prodComponent;
              },
            );
          } else {
            product.opportunityProductComponents = [
              ...product.opportunityProductComponents,
              component,
            ];
          }
          return product;
        }
      });
    }

    setOpportunity((prev) => ({ ...prev, opportunityProducts }));
    getTotalEstimatedHours();
  };

  const setPayments = (installment, id) => {
    const { opportunityPayments } = opportunity;
    if (
      Object.keys(installment).length &&
      (installment.number || installment.value || installment.dueDate)
    ) {
      opportunityPayments.map((payment) => {
        if (payment.id === id) {
          const hasInstallment = payment.opportunityInstallments.filter(
            (i) => i.id === installment.id,
          ).length;
          if (hasInstallment) {
            payment.opportunityInstallments = payment.opportunityInstallments.map(
              (opInstallment) => {
                if (opInstallment === installment.id) {
                  return { ...opInstallment, ...installment };
                }
                return opInstallment;
              },
            );
          } else {
            payment.opportunityInstallments = [...payment.opportunityInstallments, installment];
          }
          return payment;
        }
      });
    }

    setOpportunity((prev) => ({ ...prev, opportunityPayments }));
    getAmount();
  };

  const getTotalEstimatedHours = () => {
    let totalEstimatedHours = 0;

    opportunity.opportunityProducts.map((row) => {
      let totalEstimatedHour = 0;
      row.opportunityProductComponents.map((opc) => {
        if (opc.estimatedHours && !opc.deleted && !row.deleted) {
          totalEstimatedHours += opc.estimatedHours || 0;
          totalEstimatedHour += opc.estimatedHours || 0;
        }
      });
      row.totalEstimatedHour = totalEstimatedHour;
    });

    return totalEstimatedHours;
  };

  const getAmount = () => {
    let amount = 0;

    opportunity.opportunityPayments.map((row) => {
      let amountInstallment = 0;
      row.opportunityInstallments.map((oi) => {
        if (oi.value && !oi.deleted && !row.deleted) {
          if (typeof oi.value === 'string')
            oi.value = Number(oi.value.replace('R$ ', '').replace(',', '.'));

          amount += oi.value || 0;
          amountInstallment += oi.value || 0;
        }
      });
      row.amount = amountInstallment.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    });

    return amount;
  };

  const getOpportunity = async (id) => {
    setLoading(true);

    const { ok, data: opportunityRes } = await opportunityService.get(id);

    if (ok && opportunityRes) {
      opportunityRes.stage = opportunityStage[opportunityRes.stage]
        ? opportunityStage[opportunityRes.stage].value
        : 0;

      setOpportunity(opportunityRes);
      setStateBeforeUpdate(opportunityRes);

      if (opportunityRes.contacts) setLastContact(opportunityRes.contacts[0]);
    } else {
      handleModal({
        title: 'Não foi possível encontrar essa Oportunidade',
        message: null,
        show: true,
        ok: true,
      });
      history.push(`/oportunidade`);
    }

    getTotalEstimatedHours();
    getAmount();

    return setLoading(false);
  };

  const handleChange = useCallback(
    (state, target) => {
      setOpportunity((prev) => ({ ...prev, [state]: target.value }));
    },
    [opportunity],
  );

  const submitOpportunity = async () => {
    setLoading(true);

    if (!opportunity.id) {
      const dataRes = await opportunityService.save(opportunity);
      const { ok, message } = dataRes;

      if (ok) {
        const newOpportunity = dataRes.data;
        if (newOpportunity && newOpportunity.id) {
          handleModal({
            title: 'Oportunidade',
            message: message ? (
              <>
                Incluído com sucesso! <br /> {message}
              </>
            ) : (
              'Incluído com sucesso!'
            ),
            show: true,
            ok: true,
          });
          history.push(`/oportunidade/${newOpportunity.id}`);
        }
      } else {
        handleModal({ message, show: true, ok: true });
      }
    } else {
      const { data, message } = await opportunityService.edit(opportunity);
      if (data) {
        handleModal({
          title: 'Oportunidade',
          message: message ? (
            <>
              Atualizado com sucesso! <br /> {message}
            </>
          ) : (
            'Atualizado com sucesso!'
          ),
          show: true,
          ok: true,
          onHide: () => {
            handleModal({ show: false });
            getOpportunity(opportunity.id);
          },
        });
      } else {
        handleModal({ message, show: true, ok: true });
      }
    }

    setLoading(false);
  };

  const cancel = () => {
    if (checkChanges(stateBeforeUpdate, opportunity)) {
      history.push('/oportunidades');
    } else {
      handleModal({
        title: 'Atenção',
        message: 'Existem alterações nesse registro, tem certeza que deseja cancelar ?',
        show: true,
        confirm: true,
        confirmText: 'Sim',
        confirmDanger: true,
        cancel: true,
        cancelText: 'Cancelar',
        onConfirm: async () => {
          history.push('/oportunidades');
          handleModal({
            show: false,
          });
        },
      });
    }
  };

  const deleteOpportunity = async () => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir esse registro?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        const { ok, message } = await opportunityService.delete(opportunity.id);
        setLoading(true);
        if (ok) {
          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          history.push('/oportunidades');
        } else {
          handleModal({
            message,
            show: true,
            ok: true,
          });
        }
        setLoading(false);
      },
    });
  };

  return (
    <>
      <Row>
        <Col className={'d-flex d-md-none'}>
          <Button back onClick={() => cancel()} />
        </Col>
        <Col className={'d-none d-md-flex'}>
          <Breadcrumb
            routes={[
              { label: 'Oportunidades', onClick: cancel },
              { label: 'Oportunidade', active: true },
              { label: valueTab ? 'Contatos' : 'Informações', active: true },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col align={'left'}>
          <h1>Oportunidades</h1>
        </Col>
      </Row>
      <Tabs value={valueTab} setValue={setValueTab} labels={['Informações', 'Contatos']} />
      <Tab index={0} value={valueTab}>
        <Row>
          <Col sm={12} md={8} className={'mb-4'}>
            <Input
              required
              name="name"
              label="Nome"
              value={opportunity.name}
              onChange={(e) => handleChange('name', e.target)}
            />
          </Col>
          <Col sm={12} md={4} className={'mb-4'}>
            {opportunity.id && lastContact?.nextContactDate && !lastContact?.noNextContact ? (
              <Calendar
                label={'Próximo contato'}
                value={lastContact && lastContact.nextContactDate}
                readOnly
              />
            ) : (
              <Input
                name="lastContact"
                label="Próximo contato"
                value={
                  lastContact?.noNextContact
                    ? 'Não haverá próximo contato.'
                    : 'Sem próximo contato definido.'
                }
                disabled
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={3} className={'mb-4'}>
            <Calendar
              maxDate={today}
              label={'Data de inclusão'}
              value={opportunity.includedAt}
              onChange={(e) => handleChange('includedAt', e.target)}
            />
          </Col>
          <Col sm={12} md={6} lg={3} className={'mb-4'}>
            <Select
              labelId={'stage'}
              minWidth={'100%'}
              label={'Estágio'}
              Obj={opportunityStage}
              value={opportunity.stage}
              onChange={(e) => handleChange('stage', e.target)}
            />
          </Col>
          <Col sm={12} md={6} lg={3} className={'mb-4'}>
            <Select
              labelId={'status'}
              minWidth={'100%'}
              label={'Status'}
              Obj={opportunityStatus}
              value={opportunity.status}
              onChange={(e) => handleChange('status', e.target)}
            />
          </Col>
          <Col sm={12} md={6} lg={3} className={'mb-4'}>
            <Calendar
              label={'Expectativa de Conclusão'}
              value={opportunity.expectation}
              onChange={(e) => handleChange('expectation', e.target)}
              minDate={moment(lastContact?.contactDate ?? opportunity.includedAt).startOf('day')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={'mb-4'}>
            <Input
              textArea={true}
              rows={3}
              required
              name="observation"
              label="Observações relevantes para o contato"
              value={opportunity.observation}
              onChange={(e) => handleChange('observation', e.target)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} className={'mb-4'}>
            <FocalPoints
              rows={opportunity.focalPoints}
              cellsValues={(e) => handleChange('focalPoints', e.target)}
              loadData={() => getOpportunity(opportunity.id)}
            />
          </Col>
          {lastContact ? (
            <Col sm={12} md={6} className={'mb-4'}>
              <CommentBox
                contacts={opportunity.contacts}
                entityType={'lead'}
                entity={opportunity}
                service={opportunityService}
                loadingDataFunc={() => getOpportunity(opportunity.id)}
                setLastContact={setLastContact}
                lastContact={lastContact}
              />
            </Col>
          ) : (
            <Col
              sm={12}
              md={6}
              className={'text-center my-auto d-flex flex-column align-items-center'}
            >
              <div className={'py-3'}>
                <Text text={'Aguardando primeiro contato'} />
              </div>
              <Button
                text={'Registrar primeiro contato'}
                onClick={() => setValueTab(1)}
                variant="outlined"
                fillColumn
                disabled={!opportunity.id || !!opportunity.contacts.length}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={12} className={'mb-4'}>
            <h3 className={'mb-4'}>Descrição</h3>
            <Tinymce
              value={opportunity.description}
              onChange={(value) => {
                handleChange('description', { value });
              }}
            />
          </Col>
        </Row>
        <Estimate
          rows={opportunity.opportunityProducts}
          cellsValues={(e) => handleChange('opportunityProducts', e.target)}
          setProducts={setProducts}
          getTotalEstimatedHours={getTotalEstimatedHours}
          loadData={() => getOpportunity(opportunity.id)}
        />
        <OpportunityPayments
          rows={opportunity.opportunityPayments}
          cellsValues={(e) => handleChange('opportunityPayments', e.target)}
          setPayments={setPayments}
          getAmount={getAmount}
          loadData={() => getOpportunity(opportunity.id)}
        />
        <Row className={'mt-4 mb-5'}>
          <Col md={'auto'} className={'flex justify-content-center justify-content-md-start'}>
            <Accordion
              changesHistory
              options={[
                {
                  header: { icon: '', label: 'Histórico de alterações' },
                  body: <p>Registro 1</p>,
                },
              ]}
            />
          </Col>
        </Row>
      </Tab>
      <Tab index={1} value={valueTab}>
        <Contacts
          entityType={'opportunity'}
          entity={opportunity}
          contacts={opportunity.contacts}
          setEntity={setOpportunity}
          service={opportunityService}
          loadingDataFunc={() => getOpportunity(opportunity.id)}
        />
      </Tab>
      <Row className={'mb-4 pb-5'}>
        {opportunity.id ? (
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 3, offset: 0, order: 0 }}
            className={'mb-2 mb-md-0 d-flex justify-content-center justify-content-md-start'}
          >
            <Button
              text={'Excluir'}
              onClick={async () => await deleteOpportunity()}
              variant="outlined"
              fillColumn
              danger
            />
          </Col>
        ) : (
          <></>
        )}
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 3, offset: 3, order: 1 }}
          className={'mb-2 mb-md-0 d-flex justify-content-center justify-content-md-start'}
        >
          <Button
            text={'Cancelar Alterações'}
            variant="outlined"
            secondary
            fillColumn
            onClick={() => cancel()}
          />
        </Col>
        <Col
          xs={{ span: 12, order: 0 }}
          md={{ span: 3, order: 2 }}
          style={{ bottom: 10, left: 0 }}
          className={'mb-2 mb-md-0 d-flex justify-content-center justify-content-md-end'}
        >
          <Button
            fillColumn
            text={opportunity.id ? 'Salvar' : 'Incluir'}
            onClick={async () => await submitOpportunity()}
            variant="outlined"
          />
        </Col>
      </Row>
    </>
  );
};

Opportunity.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Opportunity;
