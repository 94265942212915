import React from 'react';
import Box from '@mui/material/Box';
import {
  StyleTextField as TextField,
  AccountCircleStyle as AccountCircle,
  PasswordStyle as Password,
} from './styles';

import PropTypes from 'prop-types';

export const InputLogin = ({
  name,
  placeholder,
  account,
  password,
  value,
  onChange,
  variant,
  action,
}) => {
  if (account) {
    return (
      <Box className={'d-flex align-items-end'}>
        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          id={name}
          name={name}
          label={placeholder}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          variant={variant ? variant : 'standard'}
          onKeyDown={(e) => action && action(e.code)}
        />
      </Box>
    );
  }

  if (password) {
    return (
      <Box className={'d-flex align-items-end'}>
        <Password sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          id={name}
          name={name}
          label={placeholder}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          variant={variant ? variant : 'standard'}
          type="password"
          onKeyDown={(e) => action && action(e.code)}
        />
      </Box>
    );
  }
};

InputLogin.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  account: PropTypes.bool,
  password: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  action: PropTypes.func,
  variant: PropTypes.string,
};

export default InputLogin;
