import PropTypes from 'prop-types';
import React from 'react';
import ReactECharts from 'echarts-for-react';

// docs: https://www.npmjs.com/package/echarts-for-react
// docs: https://echarts.apache.org/en/index.html

export const ECharts = ({ options }) => {
  return <ReactECharts option={options} />;
};

ECharts.propTypes = {
  options: PropTypes.any,
};

export default ECharts;
