import PropTypes from 'prop-types';
import React from 'react';
import { Span } from './styles';

const Text = ({ text, warning }) => {
  return <Span warning={warning}>{text}</Span>;
};

Text.propTypes = {
  text: PropTypes.string,
  warning: PropTypes.bool,
};

const Title = ({ text, title1 }) => {
  if (title1) {
    return <h1>{text}</h1>;
  }

  return <span>{text}</span>;
};

Title.propTypes = {
  text: PropTypes.string,
  title1: PropTypes.bool,
};

export { Text, Title };
