import { axiosDefault, handleErrors } from '../utils/defaults';

const opportunity = {
  list: async (search = '') => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/opportunity?search=${search}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  get: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/opportunity/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  getFocalPoints: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(
        `/api/opportunity/focalPoints/${JSON.stringify({ opportunityId: id })}`,
      );
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  getContact: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/opportunity/contact/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  save: async (opportunity) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.post(`/api/opportunity/`, opportunity);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  saveContact: async (opportunity, contact) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.post(`/api/opportunity/contact/${opportunity}`, contact);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  edit: async (opportunity) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.put(`/api/opportunity/${opportunity.id}`, opportunity);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  editContact: async (contact) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.put(`/api/opportunity/contact/${contact.id}`, contact);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  delete: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.delete(`/api/opportunity/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  deleteContact: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.delete(`/api/opportunity/contact/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  getByLeadId: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/opportunity/byLeadId/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default opportunity;
