exports.leadOringins = {
  aws: {
    value: 'aws',
    label: 'AWS',
  },
  recommendation: {
    value: 'recommendation',
    label: 'Indicação',
  },
  marketing: {
    value: 'marketing',
    label: 'Marketing',
  },
  site: {
    value: 'site',
    label: 'Site Institucional',
  },
  others: {
    value: 'others',
    label: 'Outros',
  },
};

exports.leadStatus = {
  notContacted: {
    value: 'notContacted',
    label: 'Não Acionado',
  },
  warming: {
    value: 'warming',
    label: 'Aquecimento',
  },
  eliminated: {
    value: 'eliminated',
    label: 'Eliminado',
  },
  opportunity: {
    value: 'opportunity',
    label: 'Oportunidade',
    hide: true,
  },
};

exports.contactTypes = {
  call: {
    value: 'call',
    label: 'Ligação',
    color: '#fac858',
  },
  whatsapp: {
    value: 'whatsapp',
    label: 'WhatsApp',
    color: '#73c0de',
  },
  email: {
    value: 'email',
    label: 'E-mail',
    color: '#3ba272',
  },
  meet: {
    value: 'meet',
    label: 'Reunião',
    color: '#ee6666',
  },
};

exports.opportunityStatus = {
  awaitFirstMeeting: {
    value: 'awaitFirstMeeting',
    label: 'Aguardando Primeira Reunião',
  },
  awaitPurpose: {
    value: 'awaitPurpose',
    label: 'Aguardando Proposta',
  },
  purposeSent: {
    value: 'purposeSent',
    label: 'Proposta Enviada',
  },
  lost: {
    value: 'lost',
    label: 'Perdido',
  },
  concluded: {
    value: 'concluded',
    label: 'Concluído',
  },
};

exports.opportunityStage = {
  0: { value: 0, label: '0%' },
  20: { value: 20, label: '20%' },
  40: { value: 40, label: '40%' },
  60: { value: 60, label: '60%' },
  80: { value: 80, label: '80%' },
  100: { value: 100, label: '100%' },
};

exports.customerSuccessCategories = {
  proative: {
    value: 'proative',
    label: 'Proativa',
  },
  relative: {
    value: 'relative',
    label: 'Reativa',
  },
};

exports.customerSuccessMainReassons = {
  comunication: {
    value: 'comunication',
    label: 'Comunicação',
  },
  returnTime: {
    value: 'returnTime',
    label: 'Tempo de Retorno',
  },
  technic: {
    value: 'technic',
    label: 'Tecnica',
  },
  retornFail: {
    value: 'retornFail',
    label: 'Retorno Com Falhas',
  },
};

exports.customerSuccessStatus = {
  provisioned: {
    value: 'provisioned',
    label: 'Provisionado',
  },
  inProgress: {
    value: 'inProgress',
    label: 'Em Andamento',
  },
  concluded: {
    value: 'concluded',
    label: 'Concluído',
  },
};

exports.priorities = {
  notContacted: {
    value: 'notContacted',
    label: 'Não Acionado',
    color: 'red',
    legend: 'Lead em alerta',
  },
  nextContactLessThanToday: {
    value: 'nextContactLessThanToday',
    label: 'Próximo contato vencido',
    color: 'red',
    legend: 'Lead em alerta',
  },
  nextContactIsToday: {
    value: 'nextContactIsToday',
    label: 'Próximo contato é hoje',
    color: 'blue',
    legend: 'Lead imediato',
  },
  nextContactGreaterToday: {
    value: 'nextContactGreaterToday',
    label: 'Próximo contato programado',
    color: 'green',
    legend: 'Lead programado',
  },
  noNextContact: {
    value: 'noNextContact',
    label: 'Sem próximo contato',
    color: 'gray',
    legend: 'Lead perdido',
  },
};
