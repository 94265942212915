import * as React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';

import { StyleSelect as SelectMUI, Form, InputLabel } from './styles';

export const Select = ({
  variant,
  labelId,
  label,
  id,
  onChange,
  minWidth,
  Obj,
  array,
  value,
  error,
  autoFocus,
}) => {
  const newObj = {};

  Obj &&
    Object.keys(Obj)
      .filter((key) => !Obj[key].hide)
      .forEach((o) => {
        newObj[o] = Obj[o];
      });

  const newArray = array && array.filter((a) => !a.hide);

  return (
    <Form variant={variant} size={'small'} label={label} sx={{ minWidth }}>
      <InputLabel>{label}</InputLabel>
      <SelectMUI
        labelId={labelId}
        id={id}
        value={value}
        label={label}
        onChange={onChange}
        error={error}
        autoFocus={autoFocus}
      >
        {newObj &&
          Object.keys(newObj).map((key) => {
            return (
              <MenuItem key={`select_${key.id}${key.value}`} value={newObj[key].value}>
                {newObj[key].label}
              </MenuItem>
            );
          })}
        {newArray &&
          newArray.map((key) => {
            return (
              <MenuItem key={`select_${key.id}${key.value}`} value={key}>
                {key.name}
              </MenuItem>
            );
          })}
      </SelectMUI>
    </Form>
  );
};

Select.propTypes = {
  Obj: PropTypes.object,
  array: PropTypes.array,
  key: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  inputLabel: PropTypes.string,
  labelId: PropTypes.string,
  itemValue: PropTypes.string,
  variant: PropTypes.string,
  itemName: PropTypes.string,
  minWidth: PropTypes.string,
  error: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default Select;
