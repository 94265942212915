import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DataGrid } from '../.';
import service from '../../services/focaPoint';
import { DefaultContext } from '../../context';
import { sortComparator } from '../../utils/helpers';
import { Phone } from './styles';

const FocalPoints = ({ rows, cellsValues, loadData }) => {
  const { handleModal, setLoading } = useContext(DefaultContext);

  const handleChange = ({ field, value, id }) => {
    let newRows = [...rows];
    const focalPoint = {};

    const ids = newRows.map((row) => row.id);

    if (ids.indexOf(id) > -1) {
      newRows = newRows.map((row) => {
        if (row.id === id) {
          row[field] = value?.trim();
          !row.new && (row.edited = true);
        }
        return row;
      });
    } else {
      focalPoint.id = id;
      focalPoint.new = id;
      focalPoint[field] = value?.trim();
      field !== 'phone' && (focalPoint.phone = null);
    }

    cellsValues({
      target: {
        value: [...newRows, focalPoint].filter((row) => !!row.name || !!row.phone || !!row.email),
      },
    });
  };

  const handleDelete = (id) => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir esse ponto focal?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        setLoading(true);
        let ok, message, load;

        if (isNaN(id)) {
          rows.map((i) => {
            if (i.id === id) i.deleted = true;
            return i;
          });
          const newRows = rows.filter((i) => !(i.new && i.deleted));
          cellsValues({ target: { value: newRows } });
          ok = true;
        } else {
          const response = await service.delete(id);
          ok = response.ok;
          message = response.message;
          load = true;
        }

        if (ok) {
          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          if (load) loadData();
        } else {
          handleModal({
            message,
            show: true,
          });
        }

        setLoading(false);
      },
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
    return re.test(String(email));
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      flex: 0.5,
      headerAlign: 'center',
      editable: true,
      sortComparator,
      renderCell: (e) => {
        if (e.value === undefined)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Nome'}
            </div>
          );
        return <div tabIndex={0}>{e.value}</div>;
      },
    },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 0.5,
      headerAlign: 'center',
      editable: true,
      sortComparator,
      renderCell: ({ value, row }) => {
        if (value === undefined && !row['phone'])
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'email@email.com '}
            </div>
          );
        return <div tabIndex={0}>{value}</div>;
      },
      preProcessEditCellProps: ({ props: { value } }) => {
        value = value?.replaceAll(' ', '');
        return { value, ...(value && { error: !validateEmail(value) }) };
      },
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      flex: 0.5,
      headerAlign: 'center',
      editable: true,
      renderEditCell: ({ api, id, value, field }) => {
        return (
          <Phone
            tabIndex={-1}
            mask={
              value && value.replaceAll('_', '').length < 15 ? '(99) 9999-99999' : '(99) 99999-9999'
            }
            onChange={({ target: { value } }) =>
              api.setEditCellValue({ id, field, value: value.replaceAll('_', '') })
            }
            value={value}
          >
            {(inputProps) => <TextField autoFocus {...inputProps} />}
          </Phone>
        );
      },
      renderCell: ({ value, row }) => {
        if (value === undefined && !row['email'])
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'(__) ____-____'}
            </div>
          );
        return <div tabIndex={0}>{value}</div>;
      },
      sortComparator,
    },
  ];

  return (
    <>
      <Row className={'mb-3 mb-md-4 mt-md-1 pb-1'}>
        <Col align={'left'}>
          <h3>
            Ponto{rows?.length > 1 ? 's' : ''} Foca{rows?.length > 1 ? 'is' : 'l'}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataGrid
            maxSize={400}
            columns={columns}
            onDelete={(e) => handleDelete(e)}
            rows={rows}
            onEditCell={(e) => handleChange(e)}
            requiredFields={['name', 'email', 'phone']}
            marginTop={10}
            headerNameColumnMobile={'Pontos Focais'}
          />
        </Col>
      </Row>
    </>
  );
};

FocalPoints.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  cellsValues: PropTypes.func,
  onDelete: PropTypes.func,
  onEditCell: PropTypes.func,
  loadData: PropTypes.func,
};

export default FocalPoints;
