import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(Modal)`
  text-align: center;
`;

export const ModalBody = styled(Modal.Body)`
  border: none;
  padding: 1rem 2rem 0;
  max-height: 50vh;
  overflow-y: auto;
`;

export const ModalFooter = styled(Modal.Footer)`
  border: none;
  display: flex;
  justify-content: center;
  min-height: 50px;
`;

export const ModalHeader = styled(Modal.Header)`
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 2.5rem;
`;
