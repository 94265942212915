import { useContext } from 'react';
import * as React from 'react';
import { MenuContext } from '../../context';
import { Accordion } from '../index';
import { MenuItem, MenuLabel, Menu, MenuItemHeader } from './styles';
import { Offcanvas } from 'react-bootstrap';

const { Header: MenuHeader, Body: MenuBody } = Menu;

import home_icon from '../../assets/icons/home-solid.svg';
import logout_icon from '../../assets/icons/sign-out-alt-solid.svg';
import comercial_icon from '../../assets/icons/shopping-bag-solid.svg';
import register_icon from '../../assets/icons/registration.svg';
export const Header = () => {
  const { show, handleClose } = useContext(MenuContext);

  const options = [
    {
      header: {
        label: 'Comercial',
        icon: comercial_icon,
      },
      body: (
        <>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            key={1}
            className="d-flex align-items-start"
            to={'/dashboard'}
          >
            <MenuLabel>{'Dashboard'}</MenuLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            key={2}
            className="d-flex align-items-start"
            to={'/leads'}
          >
            <MenuLabel>{'Leads'}</MenuLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            key={3}
            className="d-flex align-items-start"
            to={'/oportunidades'}
          >
            <MenuLabel>{'Oportunidades'}</MenuLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            key={4}
            className="d-flex align-items-start"
            to={'/customer'}
          >
            <MenuLabel>{'Customer Success'}</MenuLabel>
          </MenuItem>
        </>
      ),
    },
    {
      header: {
        label: 'Cadastros',
        icon: register_icon,
      },
      body: (
        <>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            key={5}
            className="d-flex align-items-start"
            to={'/cliente'}
          >
            <MenuLabel>{'Cliente'}</MenuLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            key={6}
            className="d-flex align-items-start"
            to={'/usuario'}
          >
            <MenuLabel>{'Usuário'}</MenuLabel>
          </MenuItem>
        </>
      ),
    },
  ];

  return (
    <Menu show={show} onHide={handleClose} keyboard>
      <MenuHeader closeButton closeVariant={'white'}>
        <Offcanvas.Title>CodeManager</Offcanvas.Title>
        {/*  <LogoMenu src={logoWhite} alt="cotton move logotipo" />*/}
      </MenuHeader>
      <MenuBody>
        <MenuItemHeader src={home_icon} key={1} className="d-flex" to={'/'}>
          {'Home'}
        </MenuItemHeader>
        <Accordion options={options} />
        <MenuItemHeader src={logout_icon} key={99} className="d-flex d-lg-none" to={'/logout'}>
          {'Sair'}
        </MenuItemHeader>
      </MenuBody>
    </Menu>
  );
};

export default Header;
