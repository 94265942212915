import { GridOverlay, ptBR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Legend from './legend';
import { Table } from './style';
import uuid from 'uuid/v4';
import moment from 'moment';
import { Button, Info } from '../.';
import { priorities } from '../../enums';

const DataGrid = ({
  columns,
  rows,
  onEditCell,
  onDelete,
  onCellClick,
  maxSize,
  rowHeightMobile,
  onRowClick,
  headerNameColumnMobile,
  requiredFields,
  legend,
  noRowsOverlayCustom,
  isSelectedRow,
  isDependent,
}) => {
  const components = {
    NoRowsOverlay: () => {
      return (
        noRowsOverlayCustom ?? <GridOverlay height="100%">Nenhum registro encontrado</GridOverlay>
      );
    },
  };
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setMobile(window.innerWidth < 768);
    });
  }, []);

  if (onEditCell) {
    let qtyRowEmpty = 0;
    if (rows.length) {
      const lastRow = rows[rows.length - 1];

      if (lastRow) {
        const columnsFiltered = columns.filter((column) => column.field !== 'delete');
        const columnsField = columnsFiltered.map((column) => column.field);

        for (let column of columnsField) {
          if (!lastRow[column] && !lastRow.new && !lastRow.edited) {
            qtyRowEmpty++;
          }
        }

        if (!(qtyRowEmpty > 1)) {
          rows = [...rows, { id: uuid() }];
        }
      }
    } else {
      rows = [...rows, { id: uuid() }];
    }
  }

  if (onDelete) {
    columns.push({
      field: 'delete',
      headerName: '',
      flex: 0.05,
      sortable: false,
      filterable: false,
      cellClassName: 'noPointer',
      renderCell: (cellValues) => {
        let isCellValuesNoEmpty = false;
        if (requiredFields) {
          requiredFields.forEach((field) => {
            if (cellValues.row[field]) {
              isCellValuesNoEmpty = true;
            }
          });
        }
        if (
          isCellValuesNoEmpty ||
          !requiredFields ||
          rows.find((r) => r.id === cellValues.row.id).edited
        ) {
          return <Button tabIndex={-1} miniDelete onClick={() => onDelete(cellValues.id)} />;
        }
        return <Info tip={'Preencha os campos ao lado para inserir novo registro'} />;
      },
    });
  }

  columns = columns.map((c) => {
    const renderCell = ({ value, colDef: { type } }) => {
      if (type === 'date' && value !== 'Sem próximo contato' && moment(value).isValid())
        return moment(value).startOf('day').format('DD/MM/YYYY');
    };
    return {
      renderCell,
      ...c,
    };
  });

  const columnMobile = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'data',
      headerName: headerNameColumnMobile ?? 'Dados',
      flex: 1,
      valueGetter: (params) => {
        let value = '';
        columns.map((e) => {
          const valueField = params.row[e.field];
          const field = e.field;

          if (field === 'priorityValue') {
            value += priorities[valueField]
              ? `<strong>Situação: </strong> ${
                  priorities[valueField] ? priorities[valueField].label : 'Eliminado'
                } \n`
              : '';
            value += priorities[valueField]
              ? `<strong>${
                  e.headerName
                }: </strong> <div style=" width: 15px; height: 15px; border-radius: 50%; background-color: ${
                  priorities[valueField] && priorities[valueField].color
                }; display: inline-block; "/>`
              : '';
          } else if (field === 'user') {
            value += valueField ? `<strong>${e.headerName}: </strong> ${valueField.name}\n` : '';
          } else if (
            valueField !== 'Sem próximo contato' &&
            moment(valueField).isValid() &&
            e.type === 'date'
          ) {
            value += valueField
              ? `<strong>${e.headerName}: </strong> ${moment(valueField).format('DD/MM/YYYY')}\n`
              : '';
          } else if (e.type === 'number' && field === 'value') {
            value += valueField
              ? `<strong>${e.headerName}: </strong> ${valueField.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}\n`
              : '';
          } else {
            value += '';

            if (valueField) {
              const styledSpan =
                e.headerName === 'Comentário'
                  ? 'overflow: hidden; text-overflow: ellipsis; position: absolute; height: 22px'
                  : '';

              value += `<strong>${
                e.headerName
              }: </strong><span style="display: inline-block; width: 145px; ${styledSpan}">${
                valueField || ''
              }</span>\n`;
            }
          }
        });
        return value;
      },
      renderCell: (cellValues) => {
        return (
          <h5 style={{ fontSize: 13 }} dangerouslySetInnerHTML={{ __html: cellValues.value }} />
        );
      },
    },
  ];

  const noCellClick = ['delete', 'action', 'priorityValue'];

  return (
    <div
      className={'d-flex flex-column align-items-center'}
      style={{
        height: maxSize ?? '60vh',
        maxHeight: 1160,
        width: '100%',
      }}
    >
      {legend ? <Legend legend={legend} /> : <></>}
      <Table
        onCellClick={(e) => {
          const { field } = e;
          if (!noCellClick.includes(field)) onCellClick && onCellClick(e);
        }}
        columns={mobile ? columnMobile : columns}
        rows={!isSelectedRow && isDependent ? [] : rows.filter((i) => !i.deleted)}
        pageSize={20}
        rowHeight={mobile ? rowHeightMobile || 52 : 52}
        rowsPerPageOptions={[20]}
        isCellEditable={() => !!onEditCell}
        onCellEditCommit={onEditCell}
        checkboxSelection={false}
        disableSelectionOnClick={false}
        disableExtendRowFullWidth={false}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={components}
        onRowClick={(e) => onRowClick && onRowClick(e)}
        requiredFields={requiredFields}
        noRowsOverlayCustom={noRowsOverlayCustom}
        showColumnRightBorder
        showCellRightBorder
      />
    </div>
  );
};

DataGrid.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  onEditCell: PropTypes.func,
  onDelete: PropTypes.func,
  onCellClick: PropTypes.func,
  onRowClick: PropTypes.func,
  requiredFields: PropTypes.array,
  optionalFields: PropTypes.array,
  maxSize: PropTypes.number,
  rowHeightMobile: PropTypes.number,
  headerNameColumnMobile: PropTypes.string,
  noRowsOverlayCustom: PropTypes.any,
  legend: PropTypes.any,
  isSelectedRow: PropTypes.bool,
  isDependent: PropTypes.bool,
};

export default DataGrid;
