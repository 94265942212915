import jwt from 'jsonwebtoken';
import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const defaultValue = {
  modal: {},
  handleModal: () => {},
  loading: {},
  setLoading: () => {},
  userLogged: {},
  decodeUser: () => {},
};

const DefaultContext = createContext(defaultValue);

const DefaultProvider = ({ children }) => {
  // const [default, setDefault] = useState(false)
  const [loading, setLoading] = useState(false);
  const [userLogged, setUserLogged] = useState({});

  const defaultStateModal = {
    title: 'Oops!',
    message: 'Esqueci o que ia falar..',
    show: false,
    onHide: () => {
      handleModal({ show: false });
    },
    ok: false,
    okText: 'OK',
    okDanger: false,
    onOk: () => {
      handleModal({ show: false });
    },
    confirm: false,
    confirmText: 'Confirmar',
    confirmDanger: false,
    onConfirm: () => {},
    cancel: false,
    cancelText: 'Cancelar',
    cancelDanger: false,
    onCancel: () => {
      handleModal({ show: false });
    },
  };
  const [modal, setModal] = useState(defaultStateModal);

  const handleModal = (att) => {
    setModal((prev) => {
      Object.keys(modal).forEach((rm) => {
        if (!Object.keys(defaultStateModal).includes(rm)) delete prev[rm];
      });
      return {
        ...prev,
        ...(!att.show ? {} : defaultStateModal),
        ...att,
        ...(att.ok && !att.onOk && att.onHide ? { onOk: att.onHide } : {}),
      };
    });
  };

  const decodeUser = () => {
    const token = localStorage.getItem('token');
    const dataToken = token && jwt.decode(token);
    const decoded = dataToken ? dataToken.data && dataToken.data.user : {};
    return setUserLogged(decoded);
  };

  return (
    <DefaultContext.Provider
      value={{
        modal,
        handleModal,
        loading,
        setLoading,
        userLogged,
        decodeUser,
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

DefaultProvider.propTypes = { children: PropTypes.node };

export { DefaultContext, DefaultProvider };
