import styled from 'styled-components';
import { Accordion as BSAccordion } from 'react-bootstrap';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

import triangle from '../../assets/icons/triangle-up.png';

const { Header: BSHeader, Body: BSBody, Item: BSItem } = BSAccordion;

export const Accordion = styled(BSAccordion)``;

export const Body = styled(BSBody)`
  padding: 7px 0 0 79px;

  @media ${bootstrapMax.lg} {
    padding: 2px 0 0 50px;
  }
`;

export const Item = styled(BSItem)`
  background-color: transparent;
`;

export const Header = styled(BSHeader)`
  .accordion-button {
    color: ${colors.white} !important;
    background-color: transparent;
    font-size: 24px;
    outline: none !important;
    box-shadow: none;
    border: 1px solid ${colors.primary.element};
    transition: all ease-in-out 0.2s;

    ::after {
      display: none;
    }

    ::before {
      content: ' ';
      width: 20px;
      height: 20px;
      margin-right: 15px;
      mask: url(${({ src }) => src}) no-repeat center;
      background-color: ${colors.white};
      transition: all ease-in-out 0.2s;
    }

    &:hover,
    &:focus {
      border: 1px solid ${colors.secondary.element};
      color: ${colors.secondary.element} !important;

      ::before {
        background-color: ${colors.secondary.element};
      }
    }

    @media ${bootstrapMax.lg} {
      font-size: 18px;
      padding: 0.5rem 0.75rem;

      ::before {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }
`;

export const HistoryHeader = styled(BSHeader)`
  .accordion-button {
    color: ${colors.white} !important;
    background-color: transparent;
    font-size: 30px;
    outline: none !important;
    box-shadow: none;
    border: 1px solid ${colors.primary.element};
    transition: all ease-in-out 0.2s;

    ::after {
      height: 10px;
      margin-left: 25px;
      background-image: url(${triangle});
      transform: rotate(180deg);
    }

    :not(.collapsed)::after {
      transform: unset;
    }

    @media ${bootstrapMax.lg} {
      font-size: 18px;
      padding: 0.5rem 0.75rem;

      ::before {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }
`;

export const HistoryAccordion = styled(BSAccordion)`
  .accordion-item {
    border: none;
  }

  .accordion-button {
    border: none;
    color: ${colors.black} !important;
    padding: 10px 0;
  }

  .accordion-collapse > div {
    padding: 0 !important;
  }

  .accordion-button:hover {
    border: none;
    color: unset !important;
  }
`;
