import React from 'react';
import Tab from '@mui/material/Tab';
import { Box, Tabs as MUITabs } from './styles';
import PropTypes from 'prop-types';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Tabs = ({ value, setValue, labels }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <MUITabs value={value} onChange={handleChange}>
        {labels &&
          labels.map((label, index) => {
            return <Tab key={index} label={label} {...a11yProps(index)} />;
          })}
      </MUITabs>
    </Box>
  );
};

Tabs.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
  labels: PropTypes.array,
};

export default Tabs;
