import React from 'react';
import PropTypes from 'prop-types';

const Legend = ({ legend }) => {
  const definitiveLegend = {};

  Object.keys(legend).map((l) => {
    definitiveLegend[legend[l].color] = { color: legend[l].color, label: legend[l].legend };
  });

  return (
    <div style={{ width: '100%' }} className={'d-flex flex-wrap my-4 px-5 justify-content-between'}>
      {Object.keys(definitiveLegend).map((l) => (
        <div key={l} className={'d-flex align-items-center'}>
          <div
            className={'me-2'}
            style={{
              width: 20,
              height: 20,
              borderRadius: '50%',
              backgroundColor: definitiveLegend[l].color,
            }}
          />
          {definitiveLegend[l].label}
        </div>
      ))}
    </div>
  );
};

Legend.propTypes = {
  legend: PropTypes.object,
};

export default Legend;
