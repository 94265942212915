import { Link } from 'react-router-dom';
import { styled as styledUi } from '@mui/material/styles';
import styled from 'styled-components';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';
import { Button as ButtonComponent } from '../../components/Button';

import bg_img from '../../assets/backgrounds/bg_login.jpg';
import logo_img from '../../assets/logos/codemanager-light.svg';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 20px;
  background-image: url(${bg_img});
  background-color: ${colors.primary.element};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerFrame = styled.div`
  max-width: 400px;
  width: 100%;
  background-color: ${colors.primaryLight.element}99;
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
`;

export const Logo = styled.div`
  min-width: 250px;
  min-height: 75px;
  background-image: url(${logo_img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media ${bootstrapMax.lg} {
  }
`;

export const LinkStyle = styledUi(Link)(({}) => ({
  '&': {
    fontSize: 14,
    color: colors.white,
    transition: 'all ease-in-out .2s',
    textDecoration: 'none',
    padding: '8px 12px',
    border: `1px solid transparent`,
  },
  '&:hover': {
    color: colors.secondaryLight.element,
    border: `1px solid ${colors.secondaryLight.element}90`,
  },
}));

export const ButtonStyle = styledUi(ButtonComponent)(({}) => ({
  '&': {
    width: 220,
    backgroundColor: `${colors.secondaryLight.element} !important`,
    border: `1px solid ${colors.secondaryLight.element}`,
  },
  '&:hover': {
    backgroundColor: `${colors.secondary.element} !important`,
  },
}));
