import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountBox';
import Password from '@mui/icons-material/Lock';
import { colors } from '../../utils/colors';

export const StyleTextField = styled(TextField)(({ error }) => ({
  '& label': {
    color: `${!error ? colors.white : ''}`,
    '&.Mui-focused': {
      color: `${!error ? colors.white : ''}`,
    },
  },
  '& input': {
    color: `${!error ? colors.white : ''}`,
    padding: '4px 10px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.white,
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: colors.white,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${!error ? colors.white : ''}`,
    },
    '&:hover fieldset': {
      borderColor: `${!error ? colors.white : ''}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${!error ? colors.white : ''}`,
    },
  },
  '& .MuiInput-root:hover': {
    '&:not(.Mui-disabled):before': {
      borderBottom: `${!error ? `1px solid ${colors.white}` : ''}`,
    },
  },
  width: '100%',
}));

export const AccountCircleStyle = styled(AccountCircle)(({}) => ({
  '&': {
    marginRight: 12,
    color: colors.white,
  },
}));

export const PasswordStyle = styled(Password)(({}) => ({
  '&': {
    marginRight: 12,
    color: colors.white,
  },
}));
