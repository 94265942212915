import { Button } from '../';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { contactTypes } from '../../enums';
import { DefaultContext } from '../../context';

const ContactButton = ({
  text,
  variant,
  entity,
  entityType,
  service,
  loadingDataFunc,
  fillColumn,
}) => {
  const { handleModal } = useContext(DefaultContext);

  return (
    <Button
      text={text}
      variant={variant}
      fillColumn={fillColumn}
      disabled={!entity.id || !entity.focalPoints.length || entity.status === 'opportunity'}
      onClick={() => {
        handleModal({
          show: true,
          entityType,
          entityId: entity.id,
          contactTypes,
          newRegister: true,
          focalPoints: entity.focalPoints,
          service,
          loadingDataFunc,
          minContactDate: entity.lastContact?.contactDate ?? entity.includedAt,
        });
      }}
    />
  );
};

ContactButton.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  service: PropTypes.object,
  loadingDataFunc: PropTypes.func,
  fillColumn: PropTypes.bool,
};

export default ContactButton;
