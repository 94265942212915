import React, { useContext, useEffect, useState } from 'react';
import { Input, Button, Badge, DataGrid } from '../../components';
import { Row, Col } from 'react-bootstrap';
import { leadStatus, leadOringins, priorities } from '../../enums';
import leadService from '../../services/lead';
import PropTypes from 'prop-types';
import { DefaultContext } from '../../context';
import { sortComparatorByDate } from '../../utils/helpers';
import { Line } from './styles';

const Leads = ({ history }) => {
  const { handleModal, setLoading } = useContext(DefaultContext);
  const [leads, setLeads] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(async () => {
    await getLeads();
  }, []);

  const getLeads = async (search) => {
    setLoading(true);

    const { ok, data, message } = await leadService.list(search);
    if (ok && data) {
      setLeads(data);
    } else
      handleModal({
        title: 'Informação',
        message,
        show: true,
      });

    return setLoading(false);
  };

  const toLead = (id) => {
    history.push(`/lead/${id}`);
  };

  const action = async (key) => {
    if (key === 'Enter') await getLeads(search);
  };

  const rowsLeads = [];
  for (let i = 0; i < leads.length; i++) {
    const oldLead = leads[i];
    const newLead = {};

    newLead.id = oldLead.id;
    newLead.name = oldLead.name;
    newLead.origin = leadOringins[oldLead.origin].label;
    newLead.status = leadStatus[oldLead.status].label;
    newLead.includedAt = oldLead.includedAt ? oldLead.includedAt : '';
    newLead.lastContact =
      oldLead.lastContact && oldLead.lastContact.nextContactDate !== '0'
        ? oldLead.lastContact.nextContactDate
        : 'Não contactado';
    newLead.priorityValue = oldLead.priorityValue;

    rowsLeads.push(newLead);
  }
  const columnsLeads = [
    {
      field: 'name',
      headerName: 'Nome',
      flex: 0.5,
      headerAlign: 'center',
    },
    {
      field: 'origin',
      headerName: 'Origem',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'includedAt',
      headerName: 'Incluído em',
      type: 'date',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortComparator: sortComparatorByDate,
    },
    {
      field: 'lastContact',
      headerName: 'Próximo contato',
      type: 'date',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      sortComparator: sortComparatorByDate,
    },
    {
      field: 'priorityValue',
      headerName: 'Prioridade',
      flex: 0.2,
      headerAlign: 'center',
      renderCell: ({ value }) => {
        return <Badge priority={value} />;
      },
      align: 'center',
    },
  ];

  return (
    <>
      <Row className={'mb-3'}>
        <Col align={'left'}>
          <h1>Leads</h1>
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col>
          <Line />
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col xs={12} md={6} className={'d-flex justify-content-center'}>
          <Input
            id={'search'}
            name={'search'}
            placeholder={'Pesquisar'}
            label={'Pesquisar'}
            size={'small'}
            search
            action={action}
            onChange={(e) => setSearch(e.target.value)}
            onClick={() => getLeads(search)}
          />
        </Col>
        <Col
          xs={12}
          md={6}
          className={'mt-md-0 mt-3 d-flex justify-content-center justify-content-md-end'}
        >
          <Button variant="outlined" text={'Cadastrar Lead'} onClick={() => toLead('')} />
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col>
          <DataGrid
            rows={rowsLeads}
            columns={columnsLeads}
            rowHeightMobile={200}
            onCellClick={(e) => toLead(e.id)}
            headerNameColumnMobile={'Leads'}
            legend={priorities}
          />
        </Col>
      </Row>
    </>
  );
};

Leads.propTypes = {
  history: PropTypes.object,
};

export default Leads;
