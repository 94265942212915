import { Breadcrumb } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const BreadcrumbItem = styled(Breadcrumb.Item)`
  a {
    font-weight: bold !important;
    color: ${colors.primary.element};

    &:hover,
    &:focus {
      color: ${colors.secondary.element};
    }
  }
`;
