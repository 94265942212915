import { axiosDefault, handleErrors } from '../utils/defaults';

const user = {
  list: async (search = '') => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/lead?search=${search}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  get: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/lead/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  getFocalPoints: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/lead/focalPoints/${JSON.stringify({ leadId: id })}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  getContact: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/lead/contact/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  save: async (lead) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.post(`/api/lead/`, lead);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  saveContact: async (lead, contact) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.post(`/api/lead/contact/${lead}`, contact);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  edit: async (lead) => {
    try {
      const axios = axiosDefault();
      const data = await axios.put(`/api/lead/${lead.id}`, lead);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  editContact: async (contact) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.put(`/api/lead/contact/${contact.id}`, contact);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  delete: async ({ id }) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.delete(`/api/lead/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },

  deleteContact: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.delete(`/api/lead/contact/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default user;
