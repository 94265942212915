import { Text } from '../';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DataGrid, ContactButton } from '../index';
import { contactTypes } from '../../enums';
import { getLabelFromEnum, sortComparatorByDate } from '../../utils/helpers';
import { DefaultContext } from '../../context';

const Contacts = ({ entity, entityType, service, loadingDataFunc }) => {
  const { handleModal, setLoading } = useContext(DefaultContext);

  const deleteContact = async (idContact) => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir esse contato?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        const { ok, data, message } = await service.deleteContact(idContact);
        setLoading(true);
        if (data && ok) {
          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          loadingDataFunc();
        } else {
          handleModal({
            message,
            show: true,
            ok: true,
          });
        }
        setLoading(false);
      },
    });
  };

  if (entity.contacts.length) {
    entity.contacts = entity.contacts.map((contact) => {
      contact.includedAt = contact.includedAt ?? '';
      contact.nextContactDate = contact.noNextContact ? 'Não haverá' : contact.nextContactDate;
      return contact;
    });
  }

  const columns = [
    {
      field: 'contactDate',
      headerName: 'Data',
      type: 'date',
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      sortComparator: sortComparatorByDate,
    },
    {
      field: 'focalPoint',
      headerName: 'Ponto Focal',
      flex: 0.4,
      headerAlign: 'center',
    },
    {
      field: 'contactType',
      headerName: 'Meio',
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ value }) => {
        return <>{getLabelFromEnum(value, contactTypes)}</>;
      },
    },
    {
      field: 'nextContactDate',
      headerName: 'Próximo contato',
      type: 'date',
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      sortComparator: sortComparatorByDate,
    },
    {
      field: 'user',
      headerName: 'Usuário',
      flex: 0.4,
      headerAlign: 'center',
      renderCell: ({ value }) => {
        return <>{value && value.name}</>;
      },
    },
    {
      field: 'comment',
      headerName: 'Comentário',
      flex: 0.9,
      headerAlign: 'center',
    },
  ];

  return (
    <>
      <Row className={'mb-3'}>
        <Col>
          <div style={{ fontSize: 24 }}>{entity.name}</div>
        </Col>
      </Row>
      {!entity.id || !entity.focalPoints.length ? (
        <Row className={'mb-4'}>
          <Col xs={'auto'} className={'justify-content-center'}>
            <Text
              text={
                !entity.id
                  ? 'É necessário incluir a entidade antes de registrar os contatos.'
                  : 'É necessário incluir os Pontos Focais antes de registrar os contatos.'
              }
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row className={'mb-4'}>
        <Col>
          <DataGrid
            rows={entity.contacts}
            maxSize={400}
            columns={columns}
            onDelete={(e) => deleteContact(e)}
            onCellClick={({ row }) => {
              handleModal({
                show: true,
                entityId: entity.id,
                contactId: row.id,
                contactTypes,
                newRegister: false,
                service,
                loadingDataFunc,
              });
            }}
            headerNameColumnMobile={'Contatos'}
          />
        </Col>
      </Row>
      <Row className={'mb-4 justify-content-center'}>
        <Col className={'d-flex justify-content-center'} sm={8} md={5} lg={4}>
          <ContactButton
            fillColumn
            text={'Registrar Novo Contato'}
            variant={'outlined'}
            entity={entity}
            entityType={entityType}
            service={service}
            loadingDataFunc={loadingDataFunc}
          />
        </Col>
      </Row>
    </>
  );
};

Contacts.propTypes = {
  entity: PropTypes.object,
  entityType: PropTypes.string,
  loadingDataFunc: PropTypes.func,
  service: PropTypes.object,
};

export default Contacts;
