import { axiosDefault, handleErrors } from '../utils/defaults';

const focaPoint = {
  delete: async (id) => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.delete(`/api/focalPoints/${id}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default focaPoint;
