import React, { useContext, useState } from 'react';
import { Text, Input, Select, Rating, Button, Modal } from '../../components';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { DefaultContext } from '../../context';
import { AreaReactInformation } from './style';

import {
  customerSuccessCategories,
  customerSuccessMainReassons,
  customerSuccessStatus,
} from '../../enums';

const { defaultStates } = require('../../utils/defaults');

const CustomerSuccesses = () => {
  const history = useHistory();
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    ...defaultStates(),
  });
  const [category, setCategory] = useState({
    category: '',
  });
  const [mainReason, setMainReason] = useState({
    mainReason: '',
  });
  const [status, setStatus] = useState({
    status: '',
  });
  const onChangeStatus = ({ target: { value: status } }) => {
    setStatus({ status });
  };
  const onChangeCategory = ({ target: { value: category } }) => {
    setCategory({ category });
  };
  const onChangeMainReason = ({ target: { value: mainReason } }) => {
    setMainReason({ mainReason });
  };

  const { handleModal, setLoading } = useContext(DefaultContext);

  const ModalCS = () => (
    <Modal text={'TEXT'} title={'Title'} cancel={'cancel'} confirm={'confirme'} />
  );

  const click = ({}) => {
    if (category.category === 'Proativa') {
      return ModalCS();
    }
  };

  const CategoryReactInformation = () => (
    <AreaReactInformation>
      <Row>
        <Col sm={6} className={'my-3'}>
          <Text label={'Manutenção: '} />
        </Col>
        <Col sm={6} className={'my-3'}>
          <Text label={'Produto: '} />
        </Col>
        <Col sm={6} className={'my-3'}>
          <Text label={'Componente: '} />
        </Col>
        <Col sm={6} className={'my-3'}>
          <Text label={'Executor: '} />
        </Col>
        <Col sm={12}>
          <Row className={'mx-5'}>
            <Text label={'Avaliação: '} />
            <Rating value={customer.score} />
          </Row>
        </Col>
      </Row>
      <Col sm={12} className={'my-2'}>
        <Input rows={5} textArea placeholder={'Area de Texto'} label={'Comentário'} />
      </Col>
    </AreaReactInformation>
  );

  return (
    <Row>
      <Col align={'center'}>
        <Row>
          <Col sm={6} className={'my-2'}>
            <Input name={'name'} placeholder={'Nome'} label={'Nome'} />
          </Col>
          <Col sm={6} className={'my-2'}>
            <Input name={'email'} placeholder={'Email'} label={'Email'} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className={'my-2'}>
            <Input password name={'password'} placeholder={'Password'} label={'Password'} />
          </Col>
          <Col sm={6} className={'my-2'}>
            <Input name={'phone'} placeholder={'Telefone'} label={'Telefone'} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className={'my-2'}>
            <Text label={'Data de abertura'} />
            <Input date name={'openDate'} />
          </Col>
          <Col sm={6} className={'my-2'}>
            <Text label={'Data de proximo contato'} />
            <Input date name={'nextContact'} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className={'my-2'}>
            <Select
              labelId={'Categoria'}
              label={'Categoria'}
              minWidth={'100%'}
              Obj={customerSuccessCategories}
              value={category.category}
              onChange={onChangeCategory}
            />
          </Col>
          <Col sm={6} className={'my-2'}>
            <Select
              labelId={'Status'}
              label={'Status'}
              minWidth={'100%'}
              Obj={customerSuccessStatus}
              value={status.status}
              onChange={onChangeStatus}
            />
          </Col>
          <Col sm={12} className={'my-2'}>
            {category.category === 'Reativa' ? (
              <>
                <Select
                  labelId={'Motivo Central'}
                  label={'Motivo Central'}
                  minWidth={'100%'}
                  Obj={customerSuccessMainReassons}
                  value={mainReason.mainReason}
                  onChange={onChangeMainReason}
                />
              </>
            ) : (
              ''
            )}
          </Col>
          {category.category === 'Reativa' && mainReason.mainReason ? (
            <Col sm={12} className={'my-2'}>
              <CategoryReactInformation />
            </Col>
          ) : (
            ''
          )}
        </Row>
        <Button text={'Submeter'} />
      </Col>
    </Row>
  );
};

export default CustomerSuccesses;
