import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { DataGrid } from '../.';
import { DefaultContext } from '../../context';
import { sortComparator } from '../../utils/helpers';
import service from '../../services/opportunityProduct';

const OpportunityProducts = ({
  rows,
  cellsValues,
  setProductId,
  setIsSelectedRow,
  getTotalEstimatedHours,
  loadData,
}) => {
  const { handleModal, setLoading } = useContext(DefaultContext);

  useEffect(() => {
    if (rows?.length && !isNaN(rows[0].id)) {
      setProductId(rows[0].id);
      setIsSelectedRow(true);
    }
  }, [rows[0]]);

  const handleRowClick = (e) => {
    const newRows = [...rows];
    const rowFiltered = newRows && newRows.filter((row) => row.id === e.row.id);

    if (rowFiltered.length) {
      setProductId(e.row.id);
      setIsSelectedRow(true);
    } else {
      setIsSelectedRow(false);
    }
  };

  const handleChange = ({ field, value, id }) => {
    let newRows = [...rows];
    const opportunityProducts = {};

    const ids = newRows.map((row) => row.id);
    const newValue = typeof value === 'string' ? value?.trim() : value;

    if (ids.indexOf(id) > -1) {
      newRows = newRows.map((row) => {
        if (row.id === id) {
          row[field] = newValue;
          !row.new && (row.edited = true);
        }
        return row;
      });
    } else {
      opportunityProducts.id = id;
      opportunityProducts.new = id;
      opportunityProducts[field] = newValue;
      opportunityProducts.totalEstimatedHour = 0;
      opportunityProducts.opportunityProductComponents = [];
    }

    const rowSelected = [...newRows, opportunityProducts].filter(
      (row) => row.id === id && !!row.name,
    );

    cellsValues({
      target: {
        value: [...newRows, opportunityProducts].filter((row) => !!row.name),
      },
    });
    getTotalEstimatedHours();
    setProductId(id);

    if (rowSelected.length) {
      setIsSelectedRow(true);
    } else {
      setIsSelectedRow(false);
    }
  };

  const handleDelete = (id) => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir esse produto?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        setLoading(true);
        let ok, message, load;

        if (isNaN(id)) {
          rows.map((i) => {
            if (i.id === id) i.deleted = true;
            return i;
          });
          const newRows = rows.filter((i) => !(i.new && i.deleted));
          cellsValues({ target: { value: newRows } });
          ok = true;
        } else {
          const response = await service.delete(id);
          ok = response.ok;
          message = response.message;
          load = true;
        }

        if (ok) {
          setIsSelectedRow(false);

          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          if (load) {
            loadData();
            getTotalEstimatedHours();
          }
        } else {
          handleModal({
            message,
            show: true,
          });
        }
        setLoading(false);
      },
    });
  };

  const columnsProducts = [
    {
      field: 'name',
      headerName: 'Produto',
      flex: 0.5,
      headerAlign: 'center',
      editable: true,
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Produto'}
            </div>
          );
        return <div tabIndex={0}>{value}</div>;
      },
    },
    {
      field: 'totalEstimatedHour',
      headerName: 'Total',
      flex: 0.25,
      headerAlign: 'center',
      align: 'center',
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined) return <div style={{ opacity: 0.4 }}>{'Total'}</div>;

        return (
          <div style={{ color: '#808080' }}>
            {value ? (value === 1 ? `${value} hora` : `${value} horas`) : '--'}
          </div>
        );
      },
      cellClassName: 'noPointer',
    },
  ];

  return (
    <DataGrid
      maxSize={280}
      columns={columnsProducts}
      onDelete={(e) => handleDelete(e)}
      rows={rows}
      onEditCell={(e) => handleChange(e)}
      requiredFields={['name']}
      marginTop={10}
      onRowClick={(e) => handleRowClick(e)}
    />
  );
};

OpportunityProducts.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  cellsValues: PropTypes.func,
  onDelete: PropTypes.func,
  onEditCell: PropTypes.func,
  onRowClick: PropTypes.func,
  setProductId: PropTypes.func,
  setIsSelectedRow: PropTypes.func,
  getTotalEstimatedHours: PropTypes.func,
  loadData: PropTypes.func,
};

export default OpportunityProducts;
