import { Autocomplete } from '@mui/material';
import styled from 'styled-components';
import { colors } from '../../utils/colors';

export const InputAutocomplete = styled(Autocomplete)(({ label, error }) => ({
  '& label.Mui-focused': {
    color: `${!error ? colors.primaryDark.element : ''}`,
  },
  '& div svg.MuiSvgIcon-root': {
    transition: `transform 0.2s`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `${!error ? colors.primaryDark.element : ''}`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${!error ? colors.primaryDark.element : ''}`,
      padding: `0 ${!label ? '50%' : 'unset'};`,
    },
    '&:hover fieldset': {
      borderColor: `${!error ? colors.primaryDark.element : ''}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${!error ? colors.primaryDark.element : ''}`,
    },
  },
  width: '100%',
}));
