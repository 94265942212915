import { Button as MUIButton } from '@mui/material';
import { styled as MUIStyled } from '@mui/material/styles';
import { colors } from '../../utils/colors';
import styled from 'styled-components';

import trash_icon from '../../assets/icons/trash_red.svg';

export const Button = MUIStyled(MUIButton)(({ danger, fillColumn, secondary, fillCell }) => ({
  width: fillColumn ? '100%' : 200,
  color: `${secondary ? colors.black : colors.white}`,
  backgroundColor: `${
    danger ? colors.danger.element : secondary ? '#949494' : colors.secondaryLight.element
  } !important`,
  border: `1px solid ${
    danger ? colors.dangerDark.element : secondary ? '#636363' : colors.secondary.element
  } !important`,
  ':hover': {
    backgroundColor: `${
      danger ? colors.dangerDark.element : secondary ? '#636363' : colors.secondary.element
    } !important`,
  },
  ...(fillCell ? { fontSize: 10, whiteSpace: 'break-spaces' } : {}),
}));

export const MiniDeleteButton = styled.div`
  content: ' ';
  width: 23px;
  height: 23px;
  -webkit-mask: url(${trash_icon}) center center no-repeat;
  background-color: ${colors.danger.element};
  cursor: pointer;
  transition: background-color ease-in-out 0.2s;

  :hover {
    background-color: ${colors.dangerDark.element};
  }
`;
