import React from 'react';
import { Redirect } from 'react-router';
import { Switch } from 'react-router-dom';
import { Menu } from '../../components';
import { AuthProvider, DefaultProvider, MenuProvider } from '../../context';

import Login from '../Login';
import Logout from '../Logout';
import Dashboard from '../Dashboard';
import User from '../User';
import CustomRoute from './CustomRoute';
import CustomerSuccesses from '../CustomerSuccesses';
import Lead from '../Lead';
import Leads from '../Lead/list';
import Opportunity from '../Opportunity';
import Opportunities from '../Opportunity/list';

const routes = [
  { path: '/login', isPrivate: false, component: Login },
  { path: '/logout', isPrivate: false, component: Logout },
  { path: '/', isPrivate: true, component: Dashboard },
  { path: '/dashboard', isPrivate: true, component: Dashboard },
  { path: '/customer', isPrivate: true, component: CustomerSuccesses },
  { path: '/usuarios', isPrivate: true, component: User },
  { path: '/usuario', isPrivate: true, component: User },
  { path: '/usuario/:id', isPrivate: true, component: User },
  { path: '/lead', isPrivate: true, component: Lead },
  { path: '/lead/:id', isPrivate: true, component: Lead },
  { path: '/leads', isPrivate: true, component: Leads },
  { path: '/oportunidade', isPrivate: true, component: Opportunity },
  { path: '/oportunidade/:id', isPrivate: true, component: Opportunity },
  { path: '/oportunidades', isPrivate: true, component: Opportunities },
];

export default function Index() {
  return (
    <AuthProvider>
      <MenuProvider>
        <Menu />
        <DefaultProvider>
          <Switch>
            {routes.map(({ path, isPrivate, component }) => (
              <CustomRoute
                key={path}
                exact
                path={path}
                isPrivate={isPrivate}
                component={component}
              />
            ))}
            <Redirect from="*" to="/" />
          </Switch>
        </DefaultProvider>
      </MenuProvider>
    </AuthProvider>
  );
}
