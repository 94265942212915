import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { DefaultContext } from '../../context';
import { Container } from './styles';

function Loading() {
  const { loading } = useContext(DefaultContext);

  return (
    <Container centered show={loading} aria-labelledby="contained-modal-title-vcenter">
      <Spinner animation="border" />
    </Container>
  );
}

Loading.propTypes = {
  loading: PropTypes.bool,
};

export default Loading;
