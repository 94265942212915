import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Input,
  Text,
  Select,
  FocalPoints,
  Contacts,
  Button,
  Tabs,
  Tab,
  Calendar,
  Accordion,
  Breadcrumb,
} from '../../components';
import { Row, Col } from 'react-bootstrap';
import { leadStatus, leadOringins } from '../../enums';
import leadService from '../../services/lead';
import opportunityService from '../../services/opportunity';
import PropTypes from 'prop-types';
import { DefaultContext } from '../../context';
import moment from 'moment';
import { checkChanges } from '../../utils/helpers';
import { CommentBox } from '../../components';

const Lead = ({ match, history }) => {
  const today = moment().startOf('day');
  const { handleModal, setLoading } = useContext(DefaultContext);
  const [lead, setLead] = useState({
    id: null,
    name: '',
    status: '',
    origin: '',
    includedAt: today,
    focalPoints: [],
    contacts: [],
  });
  const [stateBeforeUpdate, setStateBeforeUpdate] = useState(lead);
  const [lastContact, setLastContact] = useState(lead.contacts[0]);

  const [valueTab, setValueTab] = useState(0);

  const submitLead = async (opportunity = false) => {
    setLoading(true);

    const redirectOpp = async () => {
      const { ok, data } = await opportunityService.getByLeadId(lead.id);
      if (ok) history.push(`/oportunidade/${data.id}`);
    };

    if (!lead.id) {
      const dataRes = await leadService.save(lead);
      const { ok, message } = dataRes;

      if (ok) {
        const newLead = dataRes.data;
        if (newLead && newLead.id) {
          handleModal({
            title: 'Lead',
            message: message ? (
              <>
                Incluído com sucesso! <br /> {message}
              </>
            ) : (
              'Incluído com sucesso!'
            ),
            show: true,
            ok: true,
          });
          history.push(`/lead/${newLead.id}`);
        }
      } else {
        handleModal({ message, show: true, ok: true });
      }
    } else {
      if (opportunity) lead.status = 'opportunity';
      const { data, message } = await leadService.edit(lead);
      if (data) {
        handleModal({
          title: 'Lead',
          message: opportunity ? (
            'Lead convertido para oportunidade!'
          ) : data.message ? (
            <>
              Atualizado com sucesso! <br /> {data.message}
            </>
          ) : (
            'Atualizado com sucesso!'
          ),
          show: true,
          ok: true,
          onHide: () => {
            handleModal({ show: false });

            if (opportunity) {
              redirectOpp();
            } else {
              getLead(lead.id);
            }
          },
        });
      } else {
        handleModal({ message, show: true, ok: true });
      }
    }

    setLoading(false);
  };

  const cancel = () => {
    if (checkChanges(stateBeforeUpdate, lead)) {
      history.push('/leads');
    } else {
      handleModal({
        title: 'Atenção',
        message: 'Existem alterações nesse registro, tem certeza que deseja cancelar ?',
        show: true,
        confirm: true,
        confirmText: 'Sim',
        confirmDanger: true,
        cancel: true,
        cancelText: 'Cancelar',
        onConfirm: async () => {
          history.push('/leads');
          handleModal({
            show: false,
          });
        },
      });
    }
  };

  const deleteLead = async () => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir esse registro?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        setLoading(true);
        const { ok, message } = await leadService.delete(lead);
        if (ok) {
          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          history.push('/leads');
        } else {
          handleModal({
            message,
            show: true,
            ok: true,
          });
        }
        setLoading(false);
      },
    });
  };

  const handleLead = useCallback(
    (state, target) => {
      setLead((prev) => ({ ...prev, [state]: target.value }));
    },
    [lead],
  );

  useEffect(async () => {
    const { id } = match.params;
    if (id) {
      await getLead(id);
    }
  }, []);

  //TODO: confirmação para sair da tela

  // useEffect(() => {
  //   window.onbeforeunload = () => {
  //     if (!checkChanges(stateBeforeUpdate, lead)) {
  //       return 'Do you really want to leave our brilliant application?';
  //     }
  //   };
  // }, []);

  const getLead = async (id) => {
    setLoading(true);

    const { ok, data: leadRes /*, message*/ } = await leadService.get(id);

    if (ok && leadRes) {
      if (leadRes.status === 'opportunity') history.push(`/oportunidades`);
      setLead(leadRes);
      setStateBeforeUpdate(leadRes);

      if (leadRes.contacts) setLastContact(leadRes.contacts[0]);
    } else {
      handleModal({
        title: 'Não foi possível encontrar esse Lead',
        message: null,
        show: true,
        ok: true,
      });
      history.push(`/lead`);
    }

    return setLoading(false);
  };

  return (
    <>
      <Row>
        <Col className={'d-flex d-md-none'}>
          <Button back onClick={() => cancel()} />
        </Col>
        <Col className={'d-none d-md-flex'}>
          <Breadcrumb
            routes={[
              { label: 'Leads', onClick: cancel },
              { label: 'Lead', active: true },
              { label: valueTab ? 'Contatos' : 'Informações', active: true },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={9} className={'d-flex justify-content-start align-items-end'}>
          <h1>Lead</h1>
        </Col>
        <Col xs={12} md={3} className={'d-flex justify-content-end align-items-end mt-2 mb-4'}>
          {lead.id && lead.status !== 'notContacted' && !lastContact?.noNextContact ? (
            <Button
              text={'Tornar Oportunidade'}
              onClick={() => submitLead(true)}
              fillColumn
              disabled={lead.status === 'opportunity'}
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Tabs value={valueTab} setValue={setValueTab} labels={['Informações', 'Contatos']} />
      <Tab index={0} value={valueTab}>
        <>
          <Row>
            <Col sm={12} md={8} className={'mb-4'}>
              <Input
                required
                name="name"
                label="Nome"
                onChange={(e) => handleLead('name', e.target)}
                value={lead.name}
              />
            </Col>
            <Col sm={12} md={4} className={'mb-4'}>
              {lead.id && lastContact?.nextContactDate && !lastContact?.noNextContact ? (
                <Calendar
                  label={'Próximo contato'}
                  value={lastContact && lastContact.nextContactDate}
                  readOnly
                />
              ) : (
                <Input
                  name="lastContact"
                  label="Próximo contato"
                  value={
                    lastContact?.noNextContact
                      ? 'Não haverá próximo contato.'
                      : 'Sem próximo contato definido.'
                  }
                  disabled
                />
              )}
            </Col>
          </Row>
          <Row className={'align-content-center'}>
            <Col sm={12} md={4} className={'mb-4'}>
              <Calendar
                maxDate={today}
                label={'Data de inclusão'}
                value={lead.includedAt}
                onChange={(e) => handleLead('includedAt', e.target)}
              />
            </Col>
            <Col sm={12} md={4} className={'mb-4'}>
              <Select
                labelId={'Origin'}
                label={'Origem'}
                minWidth={'100%'}
                Obj={leadOringins}
                value={lead.origin}
                onChange={(e) => handleLead('origin', e.target)}
              />
            </Col>
            <Col sm={12} md={4} className={'mb-4'}>
              <Select
                labelId={'Status'}
                label={'Status'}
                minWidth={'100%'}
                Obj={leadStatus}
                value={lead.status}
                onChange={(e) => handleLead('status', e.target)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} className={'mb-4'}>
              <FocalPoints
                rows={lead.focalPoints}
                cellsValues={(e) => handleLead('focalPoints', e.target)}
                loadData={() => getLead(lead.id)}
              />
            </Col>
            {lastContact ? (
              <Col sm={12} md={6} className={'mb-4'}>
                <CommentBox
                  contacts={lead.contacts}
                  entityType={'lead'}
                  entity={lead}
                  service={leadService}
                  loadingDataFunc={() => getLead(lead.id)}
                  setLastContact={setLastContact}
                  lastContact={lastContact}
                />
              </Col>
            ) : (
              <Col
                sm={12}
                md={6}
                className={'text-center my-auto d-flex flex-column align-items-center'}
              >
                <div className={'py-3'}>
                  <Text text={'Aguardando primeiro contato com o Lead'} />
                </div>
                <Button
                  text={'Registrar primeiro contato'}
                  onClick={() => setValueTab(1)}
                  variant="outlined"
                  fillColumn
                  disabled={!lead.id || !!lead.contacts.length}
                />
              </Col>
            )}
          </Row>
          <Row className={'mt-4 mb-5'}>
            <Col md={'auto'} className={'flex justify-content-center justify-content-md-start'}>
              <Accordion
                changesHistory
                options={[
                  {
                    header: { icon: '', label: 'Histórico de alterações' },
                    body: <p>Registro 1</p>,
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      </Tab>
      <Tab index={1} value={valueTab}>
        <Contacts
          entityType={'lead'}
          entity={lead}
          contacts={lead.contacts}
          setEntity={setLead}
          service={leadService}
          loadingDataFunc={() => getLead(lead.id)}
        />
      </Tab>
      {lead.status !== 'opportunity' ? (
        <Row className={'mt-2 pb-5'}>
          {lead.id ? (
            <Col
              xs={{ span: 12, order: 3 }}
              md={{ span: 3, order: 0 }}
              className={'mb-2 mb-md-0 d-flex justify-content-center justify-content-md-start'}
            >
              <Button
                text={'Excluir Lead'}
                onClick={async () => await deleteLead()}
                variant="outlined"
                danger
              />
            </Col>
          ) : (
            <></>
          )}
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 3, offset: 3, order: 1 }}
            className={'mb-2 mb-md-0 d-flex justify-content-center justify-content-md-start'}
          >
            <Button
              text={'Cancelar Alterações'}
              variant="outlined"
              secondary
              fillColumn
              onClick={() => cancel()}
            />
          </Col>
          <Col
            xs={{ span: 12, order: 0 }}
            md={{ span: 3, order: 2 }}
            style={{ bottom: 10, left: 0 }}
            className={'mb-2 mb-md-0 d-flex justify-content-center justify-content-md-end'}
          >
            <Button
              fillColumn
              text={lead.id ? 'Salvar' : 'Incluir'}
              onClick={async () => await submitLead()}
              variant="outlined"
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

Lead.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default Lead;
