import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Installments from './installments';
import Payments from './payments';
import { Amount } from './styles';

const OpportunityPayments = ({ rows, cellsValues, setPayments, getAmount, loadData }) => {
  const [paymentId, setPaymentId] = useState('');
  const [isSelectedRow, setIsSelectedRow] = useState(false);

  const installmentFiltered = rows.filter((installment) => installment.id === paymentId);
  const installment = installmentFiltered.length
    ? installmentFiltered[0].opportunityInstallments
    : [];

  const amount = getAmount();

  return (
    <>
      <Row className={'mt-4 mb-3'}>
        <Col align={'left'}>
          <h3> Orçamento </h3>
        </Col>
      </Row>
      <Row>
        <Col className={`col-12 col-lg-6 mb-4`}>
          <Payments
            rows={rows}
            cellsValues={cellsValues}
            setPaymentId={setPaymentId}
            loadData={loadData}
            setIsSelectedRow={setIsSelectedRow}
          />
        </Col>
        <Col className={`col-12 col-lg-6 mb-4`}>
          <Installments
            rows={installment}
            cellsValues={cellsValues}
            paymentId={paymentId}
            setPayments={setPayments}
            loadData={loadData}
            isSelectedRow={isSelectedRow}
          />
        </Col>
      </Row>
      <Row className={'mb-4'}>
        <Col xs={12} className={'d-flex justify-content-end'}>
          <Amount>
            <span>Orçamento total:</span>
            <span style={{ marginLeft: '1.5rem' }}>
              {amount
                ? amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                : '--'}
            </span>
          </Amount>
        </Col>
      </Row>
    </>
  );
};

OpportunityPayments.propTypes = {
  rows: PropTypes.array,
  cellsValues: PropTypes.func,
  setPayments: PropTypes.func,
  getAmount: PropTypes.func,
  loadData: PropTypes.func,
};

export default OpportunityPayments;
