import React, { useCallback, useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import InputLogin from '../../components/InputLogin';
import {
  Container,
  ContainerFrame,
  Logo,
  LinkStyle as Link,
  ButtonStyle as Button,
} from './styles';
import { AuthContext, DefaultContext } from '../../context';

const Login = () => {
  const { handleLogin } = useContext(AuthContext);
  const history = useHistory();
  const [user, setUser] = useState({ email: '', password: '' });
  const { modal, handleModal, loading, setLoading } = useContext(DefaultContext);

  const handleChange = useCallback(
    ({ target }) => {
      setUser({ ...user, [target.name]: target.value });
    },
    [user],
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const response = await handleLogin(user);
    if (response.ok) {
      history.push('/');
    } else {
      handleModal({
        show: true,
        message: response.message,
      });
    }

    setLoading(false);
  }, [loading, modal, user]);

  const action = async (key) => {
    if (['NumpadEnter', 'Enter'].includes(key)) await handleSubmit();
  };

  return (
    <Container>
      <ContainerFrame>
        <Row>
          <Col>
            <Logo />
          </Col>
        </Row>
        <Row className={'mt-4'}>
          <Col className={'no-gutters'}>
            <Row className={'mt-2'}>
              <Col className={'no-gutters'}>
                <InputLogin
                  account
                  name={'email'}
                  placeholder={'e-mail'}
                  onChange={handleChange}
                  value={user.email}
                  action={action}
                />
              </Col>
            </Row>
            <Row className={'mt-2'}>
              <Col className={'no-gutters'}>
                <InputLogin
                  password
                  name={'password'}
                  placeholder={'senha'}
                  onChange={handleChange}
                  value={user.password}
                  action={action}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col className={'d-flex justify-content-center'}>
            <Link to="/recuperar-senha">Recuperar senha</Link>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className={'d-flex justify-content-center'}>
            <Button text={'Entrar'} onClick={handleSubmit} />
          </Col>
        </Row>
      </ContainerFrame>
    </Container>
  );
};

export default Login;
