import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { Body, Header, Loading, Modal } from '../../components';
import { AuthContext /*, DefaultContext*/ } from '../../context';
import { bootstrapMax } from '../../utils/devices';

import bkg from '.././../assets/backgrounds/bit.png';

const Background = styled.div`
  .bit {
    width: 40%;
    height: 60%;
    min-width: 530px;
    max-width: 768px;
    max-height: 572px;
    position: fixed;
    bottom: 0;
    right: 3%;
    background-image: url(${bkg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;

    @media ${bootstrapMax.sm} {
      min-width: 330px;
      height: 30%;
    }

    @media ${bootstrapMax.xs} {
      min-width: 230px;
      max-height: 452px;
    }
  }
`;

const CustomRoute = ({ isPrivate, ...rest }) => {
  const { auth, checkLogin } = useContext(AuthContext);
  // const { decodeUser } = useContext(DefaultContext);
  if (isPrivate) checkLogin();
  if (isPrivate && !auth) return (window.location.href = '/login');
  // if (auth) decodeUser();

  const showHeader = () => auth && !rest.path.includes('login');

  return (
    <Background>
      <div className={'bit'} />
      {showHeader() && <Header />}
      <Loading />
      <Modal />
      <Body>
        <Route exact={rest.exact} path={rest.path} component={rest.component} />
      </Body>
    </Background>
  );
};

CustomRoute.propTypes = { isPrivate: PropTypes.bool };

export default CustomRoute;
