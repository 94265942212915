import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../Text';
import { StyleRating as RatingComponent } from './styles';

function Rating({ value }) {
  const labels = {
    1.0: 'Muito triste',
    2.0: 'Triste',
    3.0: 'Neutro',
    4.0: 'Feliz',
    5.0: 'Muito Feliz',
  };

  const [hover, setHover] = React.useState(-1);

  return (
    <div>
      <RatingComponent
        name={'half-rating'}
        defaultValue={5}
        value={value}
        onChangeActive={(e) => {
          setHover(e);
        }}
      />
      {value !== null && <Text>{labels[value]}</Text>}
    </div>
  );
}

Rating.propTypes = {
  value: PropTypes.number,
};

export default Rating;
