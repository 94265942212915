import NumberFormat from 'react-number-format';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import styledComponents from 'styled-components';
import { colors } from '../../utils/colors';

import iconSearch from '../../assets/icons/search-solid.svg';

export const StyleTextField = styled(TextField)(({ error, search }) => ({
  input: {
    ...(search ? { padding: '8.5px 40px 8.5px 14px' } : {}),
  },
  '& label.Mui-focused': {
    color: `${!error ? colors.primary.element : ''}`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.primary.element,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${!error ? colors.primary.element : ''}`,
    },
    '&:hover fieldset': {
      borderColor: `${!error ? colors.primary.element : ''}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${!error ? colors.primary.element : ''}`,
    },
  },
  width: '100%',
}));

export const StyleTextFieldComment = styled(StyleTextField)`
  .MuiOutlinedInput-root {
    padding: 2px 14px 0 !important;
    margin-top: 2px;
  }

  .MuiOutlinedInput-input {
    padding-top: 30px;
  }
`;

export const HeaderComment = styledComponents.div`
    background-color: #f9f9f9;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;
    width: calc(100% - 6px);
    z-index: 1;
    left: 3px;
`;

const commentFields = styledComponents.div`
  font-size: 20px;
`;

export const FocalPointComment = styledComponents(commentFields)``;

export const SearchInput = styledComponents.div`
  position: relative;
  width: 100%;
`;

export const SearchIcon = styledComponents.div`
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 10px;
  background-image: url(${iconSearch});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  right: 0;
  transform: scaleX(-1);
  cursor: pointer;
  z-index: 5;
`;

export const StyleNumberFormat = styled(NumberFormat)(({ error }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${!error ? colors.primary.element : ''}`,
    },
  },
}));
