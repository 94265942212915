import styled from 'styled-components';
import { Container as BSContainer, Nav as BSNav, NavbarBrand } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { colors } from '../../utils/colors';
import { bootstrapMax } from '../../utils/devices';

import logo from '../../assets/logos/codemanager-light.svg';

export const Brand = styled(NavbarBrand)`
  width: 100%;
  height: 40px;
  background-image: url(${logo});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const Nav = styled(BSNav)`
  padding: 25px 15px;
  position: fixed;
  width: 100vw;
  background-color: ${colors.primary.element};
  z-index: 2;
  box-shadow: -5px 5px 10px ${colors.secondary.element};
  border-bottom: 1px solid ${colors.secondary.element};

  @media ${bootstrapMax.md} {
    padding: 15px 30px;
  }

  @media ${bootstrapMax.xs} {
    padding: 15px;
  }
`;

export const Container = styled(BSContainer)``;

export const HeaderItem = styled(NavLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  color: ${colors.primary.text};
  border: 1px solid ${colors.primary.element};
  transition: all ease-in-out 0.2s;

  span {
    font-size: 32px;

    @media ${bootstrapMax.xs} {
      font-size: 16px;
    }
  }

  :hover {
    border: 1px solid ${colors.secondary.element};
    color: ${colors.secondary.element};
  }
`;

export const StyledBurger = styled.div`
  width: 40px;
  height: 26px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  > div {
    width: 100%;
    height: 4px;
    background-color: ${colors.primary.text};
    transition: all ease-in-out 0.2s;
  }

  :hover {
    > div {
      background-color: ${colors.secondary.element};
    }
  }

  @media ${bootstrapMax.lg} {
    width: 35px;
    height: 24px;
  }

  @media ${bootstrapMax.xs} {
    width: 30px;
    height: 17px;

    > div {
      height: 3px;
    }
  }
`;
