import React, { useContext, useEffect } from 'react';

import { AuthContext, DefaultContext } from '../../context';

const Login = () => {
  const { handleLogout } = useContext(AuthContext);
  const { setLoading } = useContext(DefaultContext);

  useEffect(() => {
    setLoading(true);
    handleLogout();
    setLoading(false);
  });

  return <></>;
};

export default Login;
