import { axiosDefault, handleErrors } from '../utils/defaults';

const dashboard = {
  contactsGraph: async (search = '') => {
    try {
      const axios = axiosDefault();
      const { data } = await axios.get(`/api/dashboard?search=${search}`);
      return data;
    } catch (error) {
      return handleErrors(error);
    }
  },
};

export default dashboard;
