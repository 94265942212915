import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { DataGrid } from '../.';
import { DefaultContext } from '../../context';
import { sortComparator } from '../../utils/helpers';
import service from '../../services/opportunityProductComponent';
import { GridOverlay } from '@mui/x-data-grid';
import { ReactComponent as SelectionIcon } from '../../assets/icons/selection.svg';
import { Icon } from './styles';

const OpportunityProductComponents = ({
  rows,
  productId,
  setProducts,
  getTotalEstimatedHours,
  loadData,
  isSelectedRow,
}) => {
  const { handleModal, setLoading } = useContext(DefaultContext);

  const handleChange = (e) => {
    const { field, value, id } = e;
    let newRows = [...rows];
    const opportunityProductComponents = {};
    const ids = newRows.map((row) => row.id);
    const newValue = typeof value === 'string' ? value?.trim() : value;

    if (ids.indexOf(id) > -1) {
      newRows.map((row) => {
        if (row.id === id) {
          row[field] = newValue;
          !row.new && (row.edited = true);
        }
        return row;
      });
    } else {
      opportunityProductComponents.id = id;
      opportunityProductComponents.new = id;
      opportunityProductComponents.productId = productId;
      opportunityProductComponents[field] = newValue;
    }

    setProducts(opportunityProductComponents, productId);
  };

  const handleDelete = (id) => {
    handleModal({
      title: 'Atenção',
      message: 'Tem certeza que deseja excluir esse componente?',
      show: true,
      confirm: true,
      confirmText: 'Sim',
      confirmDanger: true,
      cancel: true,
      cancelText: 'Cancelar',
      onConfirm: async () => {
        setLoading(true);
        let ok, message, load;

        if (isNaN(id)) {
          rows.map((i) => {
            if (i.id === id) i.deleted = true;
            return i;
          });
          const newRows = rows.filter((i) => !(i.new && i.deleted));
          setProducts(newRows, productId);
          ok = true;
        } else {
          const response = await service.delete(id);
          ok = response.ok;
          message = response.message;
          load = true;
        }

        if (ok) {
          handleModal({
            title: 'Excluído com sucesso!',
            message: null,
            show: true,
            ok: true,
          });
          if (load) {
            loadData();
            getTotalEstimatedHours();
          }
        } else {
          handleModal({
            message,
            show: true,
          });
        }
        setLoading(false);
      },
    });
  };

  const columnsProductComponents = [
    {
      field: 'name',
      headerName: 'Componente',
      flex: 1,
      headerAlign: 'center',
      editable: true,
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Componente'}
            </div>
          );
        return <div tabIndex={0}>{value}</div>;
      },
    },
    {
      field: 'estimatedHours',
      headerName: 'Horas estimadas',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      editable: true,
      sortComparator,
      renderCell: ({ value }) => {
        if (value === undefined)
          return (
            <div tabIndex={0} style={{ opacity: 0.4 }}>
              {'Horas estimadas'}
            </div>
          );
        return <div tabIndex={0}>{value}</div>;
      },
    },
  ];

  const noRowsOverlayCustom = () => {
    return (
      <>
        <GridOverlay sx={{ mt: 1 }} className={'flex-column'}>
          <Icon component={SelectionIcon} />
          Selecione um produto
        </GridOverlay>
      </>
    );
  };

  return (
    <DataGrid
      maxSize={280}
      columns={columnsProductComponents}
      onDelete={(e) => handleDelete(e)}
      rows={rows}
      onEditCell={(e) => handleChange(e)}
      requiredFields={['name', 'estimatedHours']}
      marginTop={10}
      noRowsOverlayCustom={noRowsOverlayCustom()}
      isSelectedRow={isSelectedRow}
      isDependent={true}
    />
  );
};

OpportunityProductComponents.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.array,
  rows: PropTypes.array,
  onDelete: PropTypes.func,
  onEditCell: PropTypes.func,
  setProducts: PropTypes.func,
  getTotalEstimatedHours: PropTypes.func,
  loadData: PropTypes.func,
  isSelectedRow: PropTypes.bool,
};

export default OpportunityProductComponents;
